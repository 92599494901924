import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { path, equals } from 'ramda'
import { getRwDeviceType } from '../../common/ambient/utils'

const SENSORS = [
  { id: 'srad', name: 'Solar Radiation', category: 'Radiation', description: 'Global' },
  { id: 'srad2', name: 'Solar Radiation 2', category: 'Radiation', description: 'P.O.A' },
  { id: 'leaf', name: 'Leaf Wetness', category: 'Environment' },
  { id: 't1', name: 'Temperature 1', category: 'Temperature' },
  { id: 't2', name: 'Temperature 2', category: 'Temperature' },
  { id: 'batt2', name: 'Telemet Battery Voltage', category: 'Power' },
  { id: 'soil', name: 'Soil Moisture 1', category: 'Soil' },
]

const OFFSETS = [
  { id: 'offsetb', name: 'Barometer', description: '+/- x.x in.' },
  { id: 'offsetit', name: 'Inside Temp', description: '+/- x.x in.', isTemperature: true },
  { id: 'offsett', name: 'Air Temp', description: '+/- x.x in.', isTemperature: true }
]

const RwOffsets = ({ currentDevice, deviceActions }) => {
  const [selectedSensors, setSelectedSensors] = useState([])
  const [offsets, setOffsets] = useState({
    offsetb: 0,
    offsetit: 0,
    offsett: 0
  })

  const initialState = useMemo(() => ({
    sensors: path(['settings', 'external', 'rw', 'sensorsOffsets', 'sensors'], currentDevice) || [],
    offsets: {
      offsetb: path(['settings', 'external', 'rw', 'sensorsOffsets', 'offsetb'], currentDevice) || 0,
      offsetit: path(['settings', 'external', 'rw', 'sensorsOffsets', 'offsetit'], currentDevice) || 0,
      offsett: path(['settings', 'external', 'rw', 'sensorsOffsets', 'offsett'], currentDevice) || 0
    }
  }), [currentDevice])

  useEffect(() => {
    setSelectedSensors(initialState.sensors)
    setOffsets(initialState.offsets)
  }, [initialState])

  const hasChanges = useMemo(() => {
    return !equals(selectedSensors, initialState.sensors) ||
           !equals(offsets, initialState.offsets)
  }, [selectedSensors, offsets, initialState])

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault()
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [hasChanges])

  const handleSensorToggle = useCallback((sensorId) => {
    setSelectedSensors(prev =>
      prev.includes(sensorId)
        ? prev.filter(id => id !== sensorId)
        : [...prev, sensorId]
    )
  }, [])

  const handleOffsetChange = useCallback((offsetId, value, isTemperature) => {
    const numericValue = parseFloat(value) || 0
    
    // Validate temperature offsets are between -9.9 and 9.9
    if (isTemperature && (numericValue <= -9.9 || numericValue >= 9.9)) {
      return
    }
    
    setOffsets(prev => ({
      ...prev,
      [offsetId]: numericValue
    }))
  }, [])

  const isIP100 = /IP100/.test(getRwDeviceType(currentDevice))
  const handleSave = useCallback(() => {
    const payload = {
      sensors: selectedSensors,
      ...Object.fromEntries(
        Object.entries(offsets).map(([key, value]) => [key, value === '' ? 0 : value])
      )
    }
    console.log('Saving settings:', payload)
    const settings = currentDevice.settings
    settings.external.rw.sensorsOffsets = payload
    if (!isIP100) {
      delete settings.external.rw.sensorsOffsets.offsets
    }
    deviceActions.patch(currentDevice._id, { settings })
  }, [selectedSensors, offsets, deviceActions, currentDevice, isIP100])

  if (!usesRwOffsets(currentDevice)) {
    return null
  }

  const formatOffsetValue = (offset, value) => value
  let sensors = SENSORS
  let offsetsShow = null
  if (isIP100) {
    sensors = SENSORS.filter(sensor => sensor.id !== 'batt2')
    offsetsShow = (
      <div className='offsets-section'>
        <h3>Offsets</h3>
        <div className='offsets-list'>
          {OFFSETS.map(offset => (
            <div key={offset.id} className='offset-item'>
              <label>{offset.name}</label>
              <input
                className='form-control'
                type='number'
                step={offset.isTemperature ? '0.1' : '0.01'}
                value={formatOffsetValue(offset, offsets[offset.id])}
                onChange={(e) => handleOffsetChange(offset.id, e.target.value, offset.isTemperature)}
              />
              <span className='description'>{offset.description}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className='device-rw-offsets'>
      <h2>RainWise Sensor Settings</h2>

      <div className='sensors-section'>
        <h3>Sensors</h3>
        <div className='sensors-grid'>
          {sensors.map(sensor => (
            <label key={sensor.id} className='sensor-item'>
              <input
                type='checkbox'
                checked={selectedSensors.includes(sensor.id)}
                onChange={() => handleSensorToggle(sensor.id)}
              />
              <span>{sensor.name}</span>
            </label>
          ))}
        </div>
      </div>
      {offsetsShow}
      {hasChanges && (
        <button onClick={handleSave} className='btn btn-primary'>
          Save Settings
        </button>
      )}

      {hasChanges && (
        <div className='warning'>
          You have unsaved changes
        </div>
      )}
    </div>
  )
}

export default RwOffsets
RwOffsets.displayName = 'RwOffsets'

export function usesRwOffsets(currentDevice) {
  return getRwDeviceType(currentDevice) && ['Telemet', 'IP100', 'Station\\Station'].includes(getRwDeviceType(currentDevice))
}
