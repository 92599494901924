import React, { PureComponent } from 'react'
import classNames from 'classnames'
import {
  FormattedDataPoint,
  SummaryTableCell
} from './'
import { timeFormatForUser, padData, fcontains, removeFromArrWith, getSummaryDocDate, toggleArr, reduceSummaries, getLabel, getDisplayKeys, getDateTz, getTheDevice, theDeviceIsMine, isAdmin } from '../../common/ambient'
import {
  Loader
} from '../../components'
import PropTypes from 'prop-types'
import { insert, filter, prop, intersection, pluck, concat, find, propEq, contains, pipe, flatten, map, pick, uniq } from 'ramda'
import bindAllActions from '../../common/bindAllActions'

class SummaryTable extends PureComponent {
  static propTypes = {
    activeFilters: PropTypes.array,
    device: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onCellClick: PropTypes.func,
    allDocs: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    docs: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    details: PropTypes.bool
  }

  state = {
    expanded: [],
    toDelete: [],
    deleting: []
  }

  componentDidUpdate () {
    const { deviceActions, device } = this.props
    const { expanded } = this.state
    const currentDevice = getTheDevice(device)

    const unFetchedDays = expanded.filter(docId => {
      if (device[this._dayKey(docId)]) return false // we fetched it
      if (this._docIsPending(docId)) return false // we're fetching it
      return true
    })
    unFetchedDays.forEach(docId => {
      const doc = find(propEq('_id', docId), this._docsArr())
      const docDate = this._docDate(doc)
      deviceActions.fetchDeviceData({
        start: docDate.valueOf(),
        end: docDate.endOf('day').valueOf(),
        dataKey: this._dayKey(docId),
        macAddress: currentDevice.macAddress,
        limit: 3600,
        res: 1
      })
    })
  }

  _docDate (doc) {
    return getSummaryDocDate(moment, this.props.device, doc.dateutc)
  }

  _docIsPending (docId) {
    return contains(this._dayKey(docId), this.props.device.fetchDeviceDataKeysPending)
  }

  _dayKey (docId) {
    return docId + 'DayData'
  }

  _docsArr () {
    const { docs } = this.props
    return Array.isArray(docs) ? docs : [docs]
  }

  _expands (doc) {
    const { user, deviceActions, device, details, docs } = this.props
    if (!details) return null
    const { deleting, toDelete, expanded } = this.state
    const rowExpanded = contains(doc._id, expanded)
    let show = [
      <a key={1} className='exp' onClick={() => this.setState({ expanded: toggleArr(doc._id, expanded) })} />
    ]
    /* <span key={2}>{rowExpanded ? 'Collapse' : 'Expand'} Details</span> */
    if (this._docIsPending(doc._id)) {
      show = <Loader />
    }
    let _rowCount = 0
    const getButtonRow = () => {
      return (
        <tr key={doc._id + 'buttons' + _rowCount} className={classNames("buttons-row", {
          expanded: rowExpanded
        })}>
          <td colSpan={this._headingKeys().length + 1}>
            <div className={classNames("buttons-wrap")}>
              <div className="buttons-rounded small">
                {show}
              </div>
            </div>
          </td>
        </tr>
      )
    }
    let ret = [getButtonRow()]

    // details rows
    const dayData = device[this._dayKey(doc._id)]
    if (rowExpanded && dayData) {
      const myDeletes = pipe(
        pluck('_id'),
        intersection(toDelete)
      )(dayData)
      const getDate = getDateTz(moment, device)
      const getHeader = () => {
        const removeMyDeletes = () => this.setState({ toDelete: removeFromArrWith(fcontains(myDeletes), toDelete) })
        const btns = myDeletes.length > 0
          ? [
            <a key='clear' onClick={removeMyDeletes} className='clear-selections' />,
            <a
              key='trash'
              onClick={() => {
                const rows = dayData.filter(pipe(prop('_id'), fcontains(myDeletes)))
                const message = 'Are you sure you want to delete ' + rows.length + ' row' + (rows.length === 1 ? '' : 's') + ' from ' + getDate(rows[0].dateutc).format('MMM Do YYYY') + '?'

                // entire day
                if (dayData.length === rows.length) {
                  if (window.confirm('Are you sure you want to delete the entire day of ' + getDate(rows[0].dateutc).format('MMM Do YYYY') + '?')) {
                    const docDates = uniq(rows.map(d => getDate(d.dateutc).startOf('day').valueOf()).filter(Boolean))
                    if (docDates.length !== 1) {
                      window.alert('You can only delete one day at a time')
                      return
                    }
                    const summary = docs.find(doc => getDate(doc.dateutc).startOf('day').valueOf() === docDates[0])
                    if (summary) {
                      this.setState({
                        deleting: myDeletes
                      })
                      deviceActions.removeDeviceData([summary])
                        .then(() => deviceActions.removeDeviceData(rows))
                        .then(() => {
                          removeMyDeletes()
                          this.setState({
                            deleting: []
                          })
                        })
                    }
                  }
                  return
                }
                if (window.confirm(message)) {
                  this.setState({
                    deleting: myDeletes
                  })
                  deviceActions.removeDeviceData(rows)
                    .then(() => {
                      removeMyDeletes()
                      this.setState({
                        deleting: []
                      })
                    })
                }
              }}
              className='trash'
            />
            ]
          // : null
          : <input type='checkbox' onChange={() => this.setState({ toDelete: concat(toDelete, pluck('_id', dayData)) })} />
        return (
          <tr key={doc._id + 'heading' + _rowCount} className={classNames("day-data-header date-line", {
            'not-first': _rowCount > 0
          })}>
            <td className="dateutc flex">
              <div className="btns">{btns}</div>
              <div className="date">{this._docDate(doc).format('MMM D')}<br/>Details</div>
            </td>
            {this._headingFields()}
          </tr>
        )
      }
      ret.push(getHeader())
      ret = pipe(map(d => {
        _rowCount++
        const showCheckbox = d => {
          if (!d._id) return false
          if (contains(d._id, deleting)) return false
          if (theDeviceIsMine(device) || isAdmin(user)) return true
        }
        const r = [
          <tr key={_rowCount + 'row'} className='day-data'>
            <td key="dateutc" className='dateutc flex'>
              <div className='btns'>
                {showCheckbox(d)
                  ? <input type='checkbox' checked={contains(d._id, toDelete)} onChange={() => this.setState({ toDelete: toggleArr(d._id, toDelete) })} />
                  : null}
              </div>
              <div className='date'>
                {getDate(d.dateutc).format(timeFormatForUser(user))}
                {d.historical ? <sup className='historical'>*</sup> : null}
              </div>
            </td>
            {this._headingKeys().map(type => <td key={_rowCount + type}>
              <FormattedDataPoint type={type} row={d} />
              </td>)
            }
          </tr>
        ]
        if (_rowCount % 20 === 0) r.push(getHeader())
        return r
      }),
      flatten,
      concat(ret)
      )(padData(5, dayData))
      ret.push(getButtonRow())
    }
    return ret
  }

  /*
  * @param render - when we render the table for summaries we want to remove all rain params
  *  other than hourly and daily. other than that show all the rain params
  */
  _headingKeys (render) {
    const { details, activeFilters } = this.props
    let ret = getDisplayKeys(this._docsArr())
    if (render && !details) {
      ret = filter(key => {
        if (/rain/.test(key)) return key === 'hourlyrainin' || key === 'dailyrainin'
        return true
      }, ret)
    }
    const dailyraininI = ret.indexOf('dailyrainin')
    if (!details && dailyraininI > -1) {
      ret = insert(dailyraininI + 1, 'rainTotal', ret)
    }
    if (activeFilters && activeFilters.length > 0) {
      return filter(key => {
        if (/rain/.test(key)) return fcontains(activeFilters, 'rain')
        return fcontains(activeFilters, key)
      }, ret)
    }
    return ret
  }

  _headingFields (render) {
    const headingKeys = this._headingKeys(render)
    return headingKeys.map((key) => {
      return <th key={key}>{getLabel(key, this.props.device)}</th>
    })
  }

  render () {
    const { userActions, allDocs, onCellClick, children, device, details } = this.props
    const docs = this._docsArr()
    if (!docs) return <div />

    const getDate = getSummaryDocDate(moment, device)
    const endDate = getDate(docs[0].dateutc)
    const startDate = docs.length > 1 ? getDate(docs[docs.length - 1].dateutc) : false

    const headingFields = this._headingFields(true)

    let _count = 0
    const getCells = (prop, doc) => {
      _count++
      return map((k) => {
        return (
          <td className={classNames({ today: !doc._id })} key={_count + k}>
            <SummaryTableCell
              prop={prop}
              type={k}
              doc={doc}
              details={details}
              onClick={onCellClick}
            />
          </td>
        )
      }, this._headingKeys(true))
    }
    let _rowCount = 0
    const threeRows = (docs, before, after) => {
      _rowCount++
      return (
        <tbody key={_rowCount}>
          {before}
          <tr key={_rowCount + 'avg'} className="avg">
            <td className="dateutc avg">Average</td>
            {getCells('avg', docs)}
          </tr>
          <tr key={_rowCount + 'high'} className="high">
            <td className="dateutc high">High</td>
            {getCells('h', docs)}
          </tr>
          <tr key={_rowCount + 'low'} className="low">
            <td className="dateutc low">Low</td>
            {getCells('l', docs)}
          </tr>
          {after}
      </tbody>
      )
    }
    const getTableHeader = (i = 0) => {
      return (
        <tr key={i + 'table-header'}>
          <th className="dateutc" key='dateutc'>&nbsp;</th>
          {headingFields}
        </tr>
      )
    }
    let show, docsToUse
    if (details) {
      docsToUse = allDocs || docs
      show = pipe(
        map(doc => {
          return threeRows(
            doc,
            [
              _rowCount % 4 == 0 && _rowCount > 0 ? getTableHeader(_rowCount) : null,
              <tr id={doc.dateutc} key={_rowCount + 'heading'} className='date-line'><td className='dateutc'>{getDate(doc.dateutc).format('MMM D, YYYY')}</td><td colSpan={headingFields.length}>&nbsp;</td></tr>
            ],
            this._expands(doc)
          )
        }),
        flatten
      )(docs)
    } else {
      docsToUse = reduceSummaries(docs)
      show = threeRows(docsToUse)
    }
    return (
      <div className='block device-summary-table'>
        <header>
          <div>
            <span className='label'>{details ? 'Details' : 'Summary'}: </span>
            <span>{(startDate ? startDate.format('MMMM D, YYYY') + ' - ' : '') + endDate.format('MMMM D, YYYY')}</span>
          </div>
          <div>
            {children ? <div className='children'>{children}</div> : null}
            <a
              className='csv'
              onClick={() => userActions.doModal({
                type: 'export',
                theDevice: pick(['lastData', 'macAddress', 'tz'], getTheDevice(device)),
                start: startDate.valueOf(),
                end: endDate.valueOf()
              })}
              title='Download CSV'
              tabIndex={-1}
            />
          </div>
        </header>
        <div className='device-device-data-table'>
          <div className='scroller'>
            <table className={classNames('table-row table table-striped main', {
              details
            })}
            >
              <thead>
                {getTableHeader()}
              </thead>
              {show}
            </table>
          </div>
          {children ? <div className='children bottom'>{children}</div> : null}
        </div>
      </div>
    )
  }
}

export default bindAllActions(SummaryTable)

SummaryTable.displayName = 'SummaryTable'
