import React from 'react';
import { ProtectedLink } from '../../components';
import bindUserActions from '../user/redux/bindUserActions';
import { isApp } from '../../common/ambient/utils';
import { isLoggedIn } from '../../common/ambient';
// import PropTypes from 'prop-types';

function AwnPlusPage({ actions, user }) {
  const signupButton = (
    <div className='cta'>
        <ProtectedLink
          onClick={() => {
            actions.doModal({ type: 'plus-subscription' })
          }}
          ><button className='btn btn-primary btn-lg'>Sign up for <i className='awn-plus' /></button></ProtectedLink>
    </div>
  )
  const upgradeToday = []
  if (!isApp()) {
    upgradeToday.push(
      <div key='apps' class="awn-plus-upgrade-today-images">
      <a href="https://apps.apple.com/us/app/ambient-weather-dashboard/id1426025887" target="_blank"><img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/ios-app-store.png" alt="AWN in the iOS App Store" /></a>
      <a href="https://play.google.com/store/apps/details?id=net.ambientweather.dashboard" target="_blank"><img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/google-play-store.png" alt="AWN in the Google Play Store" /></a>
    </div>
    )
  }
  upgradeToday.push(
  )
  return (
    <div className="user-awn-plus-page">
      <div className='page-body'>
        <div id="awn-plus">
          <div class="awn-plus-header">
            <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-landing-header.png" alt="AWN+ From Ambient Weather" />
          </div>
          <div class="awn-plus-new-level">
            <h2>A Whole New Level of Weather Data at your Fingertips!</h2>
            <div class="awn-plus-new-level-text-image">
              <div class="awn-plus-new-level-text">
                <p>Tired of juggling multiple apps for weather updates? With the Ambient Weather Network (AWN), everything you need is in one ad-free app. While the free version covers the essentials, upgrading to <span>AWN+</span> unlocks premium features like advanced map layers, degree day tiles, enhanced graphing, text alerts, and a 10-Day Hourly Forecast—keeping you informed and prepared for whatever the weather brings.</p>
              </div>
              <div class="awn-plus-new-level-image">
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-new-level-screenshots.png" alt="" />
              </div>
            </div>
          </div>
          {signupButton}
          
          <div class="awn-plus-map-layers">
            <h2>Premium Map Layers</h2>
            <p>These layers bring advanced, detailed weather insights in one ad-free app, ensuring you're always prepared for whatever weather comes your way.</p>
            
            <div class="awn-plus-map-layers-maps">
              <div class="awn-plus-map-layers-map">
                <div class="awn-plus-map-layers-map-image">
                  <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plu-premium-map-layers-air-quality.png" alt="" />
                </div>
                <div class="awn-plus-map-layers-map-text">
                  <h3>Air Quality</h3>
                  <p>The Air Quality map layer provides Air Quality Index (AQI) data globally, updated every 12 hours, and categorized from "Good" to "Hazardous." You can view the AQI and its associated category by clicking on the map, making it perfect for planning outdoor activities—especially for those with respiratory issues. You can also view the color overlay on the map to determine your region's AQI category.</p>
                </div>
              </div>
              
              <div class="awn-plus-map-layers-map">
                <div class="awn-plus-map-layers-map-image">
                  <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-premium-map-layers-snow-depth.png" alt="" />
                </div>
                <div class="awn-plus-map-layers-map-text">
                  <h3>Snow Depth</h3>
                  <p>This layer offers snow accumulation data in inches and is updated every 6 hours. You can either view the color-coded map overlay or click on a colored region to access additional details like snow rate, visibility, temperature, wind speed, and more. It's ideal for anyone preparing for snowstorms or planning winter trips, helping you stay ahead of changing winter conditions.</p>
                </div>
              </div>
              
              <div class="awn-plus-map-layers-map">
                <div class="awn-plus-map-layers-map-image">
                  <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-premium-map-layers-fires.png" alt="" />
                </div>
                <div class="awn-plus-map-layers-map-text">
                  <h3>Fires</h3>
                  <p>The Fires map layer displays active wildfires across the U.S. and Canada, with information on fire start date, time, cause, area, and containment percentage. The fire icons on the map show active fires; select any of the icons to gain crucial insights. If you click outside the icons, it will find the nearest fire to that location This map layer is critical for safety and preparedness if you live in a fire-prone region.</p>
                </div>
              </div>
              
              <div class="awn-plus-map-layers-map">
                <div class="awn-plus-map-layers-map-image">
                  <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-premium-map-layers-tropical-cyclones.png" alt="" />
                </div>
                <div class="awn-plus-map-layers-map-text">
                  <h3>Tropical Cyclones</h3>
                  <p>This layer tracks active tropical cyclones such as invests, tropical depressions, tropical storms, hurricanes, and typhoons across the globe. It provides detailed storm data like names, tracks, and forecasts sourced from the National Hurricane Center (NHC), Central Pacific Hurricane Center (CPHC), and Joint Typhoon Warning Center (JTWC). It is essential for those in coastal areas to receive timely information to protect their property and loved ones from severe tropical weather.</p>
                </div>
              </div>
              
              <div class="awn-plus-map-layers-map">
                <div class="awn-plus-map-layers-map-image">
                  <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-premium-map-layers-river-gauge.png" alt="" />
                </div>
                <div class="awn-plus-map-layers-map-text">
                  <h3>River Gauge</h3>
                  <p>The River Gauge map layer updates every hour, tracking river levels and flow rates with data from NOAA's Advanced Hydrologic Prediction Service (AHPS). It delivers critical insights on water height, flow, and potential flood impacts. Whether you're boating, swimming, or fishing, this feature provides real-time data to help you plan your activities safely, ensuring you know the water conditions before heading out. It's also essential for anyone living near rivers or flood-prone areas, helping you stay ahead of flooding risks and take proactive steps to safeguard your home.</p>
                </div>
              </div>
            </div>
          </div>

          {signupButton}
          
          <div class="awn-plus-advanced-graphing">
            <h2>Advanced Graphing</h2>
            <div class='embed-container'><iframe src='https://player.vimeo.com/video/1019928551' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
            
            <div class="awn-plus-advanced-graphing-graph-text">
              <div class="awn-plus-advanced-graphing-graph">
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-advanced-graphing-screenshot1.png" alt="" />
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-advanced-graphing-screenshot2.png" alt="" />
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-advanced-graphing-screenshot3.png" alt="" />
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-advanced-graphing-screenshot4.png" alt="" />
              </div>
              <div class="awn-plus-advanced-graphing-text">
                <p>Advanced Graphing on AWN+ gives you powerful new ways to view, customize, and share your weather data.</p>
                <ul>
                  <li><strong>Multi-Station and Multi-Parameter Graphing:</strong> Graph a single parameter across multiple stations or multiple parameters from a single station, all on a single graph. Want to compare temperatures at home and at your favorite vacation spots? Done. Or see how temperature, wind speed, and humidity change over time at your shore house? Done. Simply select up to three stations or three parameters to compare in each graph.</li>
                  <li><strong>Multi-Axis Graphing:</strong> The multi-axis graph display lets you plot measurements with different units on a single timeline. For instance, while temperature, humidity, and wind speed use separate units, multi-axis graphing enables you to view them together in one unified graph. You can display parameters as either a bar chart or a line chart, and even combine both options in a single graph.</li>
                  <li><strong>Improved Graph Controls:</strong> The enhanced zoom feature provides a closer look at specific sections of your graph’s timeline. On a desktop, click and drag to select the area you want to zoom into. On the mobile app, just use the pinch-to-zoom gesture. Easily turn parameters or stations off or on by clicking or tapping on the chart legend.</li>
                  <li><strong>Organization:</strong> You can minimize any Advanced Graph while keeping its name visible for easy access, duplicate any graph to quickly edit the parameters or stations, and rearrange the order of existing graphs using the list order controls.</li>
                  <li><strong>Export Your Graphs and Data:</strong> Export and share your detailed weather data as a CSV or XLS file, or by saving your graphs as image files.</li>
                </ul>
              </div>
            </div>
          </div>

          {signupButton}
          
          <div class="awn-plus-bonus-tiles">
            <h2>Bonus Dashboard Tiles</h2>
            <div class="awn-plus-bonus-tiles-images">
              <div class="awn-plus-bonus-tiles-image">
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-bonus-dashboard-tiles-gdd.png" alt="" />
              </div>
              <div class="awn-plus-bonus-tiles-image">
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-bonus-dashboard-tiles-cdd.png" alt="" />
              </div>
              <div class="awn-plus-bonus-tiles-image">
                <img src="https://ambientweather.com/media/wysiwyg/smartwave/porto/ambient/images/awn-plus-bonus-dashboard-tiles-hdd.png" alt="" />
              </div>
            </div>
            
            <p>AWN+ includes Growing Degree Days, Cooling Degree Days, and Heating Degree Days tiles. These tiles  will empower users to:</p>
            <ul>
              <li><strong>Monitor Energy Usage:</strong> Track your building's energy consumption and identify opportunities for savings.</li>
              <li><strong>Optimize HVAC Settings:</strong> Receive recommendations on optimal HVAC settings based on local CDD and HDD data.</li>
              <li><strong>Plan Maintenance:</strong> Schedule preventive maintenance for your HVAC systems to ensure they operate efficiently throughout the year.</li>
              <li><strong>Manage Agricultural and Gardening Activities:</strong> Utilize GDD data to plan and optimize crop schedules for better yield and pest management.</li>
            </ul>
            <p>By leveraging CDD, HDD, and GDD data, you can make informed decisions that lead to significant cost savings and a reduced environmental footprint. Upgrade to the premium version of the AWN app today and take control of your energy and agricultural management!</p>
          </div>
          
          <div class="awn-plus-addl-text">
            <h2>Forecasts and Historical Data</h2>
            <ul>
              <li><strong>Extended Hourly Forecasts:</strong> Look farther into your weather future with <span>AWN+</span>, from 3 days with AWN’s free tier to <strong>a full 10 days</strong> of detailed hourly predictions.</li>
              <li><strong>Archived Weather Data:</strong> Look farther into your weather past. AWN’s free tier gives you 3 years of historical data for your station, while <span>AWN+</span> stores <strong>up to 10 years</strong> of detailed weather data!</li>
            </ul>
            
            <h2>Why Choose AWN+?</h2>
            <p>Subscribing to <strong>AWN+</strong> consolidates multiple weather parameters into one seamless experience. No more flipping between apps or dealing with intrusive ads. With the premium layers, you gain:</p>
            <ul>
              <li><strong>Comprehensive Data:</strong> Access detailed information that's not available in the free version.</li>
              <li><strong>Enhanced Safety:</strong> Stay alert to environmental hazards like poor air quality or nearby fires.</li>
              <li><strong>Convenience:</strong> Enjoy an ad-free interface with all the data you need at your fingertips.</li>
            </ul>
            
            <h2>Stay Informed, Stay Prepared</h2>
            <p>Weather can be unpredictable, but your response doesn't have to be. With AWN+, you can make informed decisions, whether rescheduling a hike due to poor air quality or preparing your home for an approaching storm.</p>	
          </div>

          <div class="awn-plus-upgrade-today">
            <h2>Upgrade Today!</h2>
            <div class="awn-plus-upgrade-today-images-text">
              {upgradeToday}
              <div class="awn-plus-upgrade-today-text">
                <p>Experience the full spectrum of weather insights by subscribing to <span>AWN+</span>.</p>
                {signupButton}
                {!isLoggedIn(user) && (
                  <p>Once you create a free account and log in, you can upgrade by clicking the <span>AWN+</span> icon in your menu or on the map. Empower yourself with advanced features and enjoy the simplicity of an all-in-one, ad-free weather app.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default bindUserActions(AwnPlusPage);

AwnPlusPage.propTypes = {};
AwnPlusPage.defaultProps = {};

AwnPlusPage.displayName = 'AwnPlusPage'
