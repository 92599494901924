import React, { PureComponent } from 'react';
import InputRange from 'react-input-range'
import PropTypes from 'prop-types';
import * as turf from '@turf/helpers'
import booleanIntersects from '@turf/boolean-intersects'
import bboxPolygon from '@turf/bbox-polygon'
import { pluck, omit, filter, split, test, range, isEmpty, mapObjIndexed, pipe, not, uniqBy, find, propEq, concat, map, path, nth, apply, groupBy, pathEq, contains, merge, uniq, identity, splitEvery, flatten, anyPass, gt, lt, last } from 'ramda'
import { formatCoords, deviceIdsWithActivePosts, isCordova, MAP_LAYER_CONFIG, getLayerParam, noMaps, toggleArr, fcontains, roundDownMins, isDev, convertUnitForUser, isSomething, hexToRgb, aeris, pathsChanged, debounce, FORECAST_ZOOM, tempColor, getSuffForUser, setStorage, getStorage, showDeviceOnMap, aerisData, latLonDistance, hasWebcam, isAdmin, isGtBreakpoint, isRole, postIsExpired, postIsActive, isIos, getTheme, trackEvent, isFav, hasVideo, deviceHasEnhancedCam, centerPointForGeo } from '../common/ambient'
import classNames from 'classnames'
import bindAllActions from '../common/bindAllActions'
import {
  DevicePopup, FormattedDataPoint
} from '../features/device'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import store from '../common/store'
import { getLegalName, getThemeObj } from '../common/skinner.js'
import { isPlus } from '../features/payment';
import ProtectedLink from './ProtectedLink';
import { DataPopup, MapLegend } from '../features/common';
import { MAP_LEGENDS } from '../common/ambient/map';
import { WindParticleLayer, WindSpeedLayer, getFirstSymbolLayer } from '../common/lib/accuweather/wind-map.js';

const toTime = (t) => new Date(t).getTime()

const getDeviceGeo = path(['info', 'coords', 'geo'])
const MAIN_ZOOM_BREAKPOINT = 6.5
const PNG_MARKERS = [
  ['wind', 'marker-wind'],
  ['lightning', 'lightning'],
  ['webcam', 'camera'],
  ['video', 'video'],
  ['video-enhanced-1', 'video-enhanced-1'],
  ['video-enhanced-2', 'video-enhanced-2'],
  ['social', 'chat'],
  ['alert', 'alert'],
  ['fav', 'fav']
]
// 'air-quality', 
const ANIMATION_FRAME_DURATION = 800
const ANIMATION_HOURS = 2
const GENERIC_DOT_COLOR = '#09a8e6'
let MARKER_IMG_BASE = window.location.protocol + '//' + window.location.host

const getGroupedDeviceLayers = (getLayerIdFn, getFeatureFn, devices) => pipe(
  groupBy(getLayerIdFn),
  mapObjIndexed(map(getFeatureFn))
)(devices)

const deviceIsOfficial = d => /noaa-/.test(d.macAddress) || /iata-/.test(d.macAddress) || /aeris-/.test(d.macAddress)
const POPUP_LAYER_ID = 'popup'


class Map extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    device: PropTypes.object,
    socialActions: PropTypes.object,
    timelineTime: PropTypes.number,
    noScrollZoom: PropTypes.bool,
    mapOpts: PropTypes.object, // see _mapOpts() for definition
    onMapLoad: PropTypes.func,
    layer: PropTypes.string,
    onDeviceClick: PropTypes.func,
    onViewDashboardClick: PropTypes.func,
    setSidebarChecked: PropTypes.func, // this one is obscure, but it's being used in transition 10.1.21 olw ;)
    mapBounds: PropTypes.array, // [ [lat, lon], [lat, lon] ] - sets bounds of map, populates dots within bounds
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    coords: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // if you want the map centered somewhere
    pinCoords: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }
  // static contextTypes = {
  //   store: PropTypes.object.isRequired
  // }
  state = {
    addLayers: [],
    userInteracted: false,
    animationTime: false,
    plusOpen: false,
  }
  constructor(props) {
    super(props)
    this.id = 'map_' + parseInt(Math.random() * 10000, 10)
    this.onReadyCalled = false 
    this.onReady = []
    this._addVendorLayers = debounce(this._addVendorLayers.bind(this), 800)
    this.devices = []
    this.sources = {}
    this.deviceLayers = {}
    this.images = {}
    this._getLayerIdForDevice = ::this._getLayerIdForDevice
    this._getFeatureForDevice = ::this._getFeatureForDevice
    this._getFeatureForPost = ::this._getFeatureForPost
    this._lightningLayers = ::this._lightningLayers
    this.searchMap = debounce(this.searchMap.bind(this), 200)
    this._updateDeviceDots = debounce(this._updateDeviceDots.bind(this), 250)
    this._deleteLayer = ::this._deleteLayer
    this._removeLayer = ::this._removeLayer
    this._removeSource = ::this._removeSource
    this._setDevices = ::this._setDevices
    this._shouldDotPulse = ::this._shouldDotPulse
  }
  _onReady(fn) {
    if (this.onReadyCalled) {
      fn()
    } else {
      this.onReady.push(fn)
    }
  }
  _shouldDotPulse(d) {
    const { focusDevice, hoverDevice } = this.props.device
    // not active if they're hovering on the map --------------------- VVVVVV
    return d && path(['_id'], hoverDevice) === d._id && path(['_id'], this._hoverDevice) !== d._id
  }
  _layer() {
    return this.props.layer || this.props.device.mapLayer
  }
  _getLayerIdForDevice(d) {
    const { user, device, social } = this.props
    const { focusDevice, hoverDevice } = device
    const layer = this._layer() 
    const layerParam = getLayerParam(layer)
    if (layerParam === 'windgustmph') {
      return 'wind' 
    }
    if (layer === '' && isFav(social, d)) {
      return 'fav'
    }
    if (layer === '' && hasWebcam(d)) {
      let ret = 'webcam'
      const camTier = deviceHasEnhancedCam(d)
      if(camTier) {
        ret = 'video-enhanced-1'
        if (parseInt(camTier, 10) === 2) {
          ret = 'video-enhanced-2'
        }
      }
      return ret
    }
    let color = GENERIC_DOT_COLOR.replace('#', '') 
    if (getLayerParam(layer) === 'tempf') {
      const tempf = path(['lastData', 'tempf'], d)
      if (isSomething(tempf)) { 
        color = tempColor(tempf).replace('#', '')
      }
    }
    const activeSuff = this._shouldDotPulse(d) ? '-active' : ''
    if (deviceIsOfficial(d)) {
      return 'official-' + color + activeSuff
    } else {
      return 'pws-' + color + activeSuff
    }
  }
  setPin (coords) {
    if (!coords) return
    this._onReady(() => {
      const layerId = 'pin'
      const data = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coords
          }
        }]
      }
      if (!this.sources[layerId]) {
        this.sources[layerId] = {
          type: 'geojson',
          data
        }
        this.map.loadImage(MARKER_IMG_BASE + '/marker-pin.png', (err, img) => {
          if (err) throw err
          if (!this.map.hasImage(layerId)) {
            this.map.addImage(layerId, img)
          }
          this.map.addSource(layerId, this.sources[layerId])
          this.map.addLayer({
            id: layerId,
            type: 'symbol',
            source: layerId,
            layout: {
              'icon-image': layerId,
              'icon-size': 0.9,
              'icon-anchor': 'bottom',
              'icon-allow-overlap': true
            },
            paint: {
              'icon-translate': [0, 5]
            }
          })
          this._pinLoaded = true
          this._reorderLayers()
        })
      } else {
        const source = this.map.getSource(layerId)
        if (source) {
          source.setData(data)
          this._reorderLayers()
        }
      }
    })
  }
  _doPhil () {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 27); // January 27th
    const end = new Date(now.getFullYear(), 1, 7); // February 7th

    if (now < start || now > end) {
      return;
    }
    const coords = [-78.95778, 40.93028]
    this._onReady(() => {
      const layerId = 'phil'
      const data = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coords
          }
        }]
      }
      if (!this.sources[layerId]) {
        this.sources[layerId] = {
          type: 'geojson',
          data
        }
        this.map.loadImage(MARKER_IMG_BASE + '/groundhog_map_icon.png', (err, img) => {
          if (err) throw err
          if (!this.map.hasImage(layerId)) {
            this.map.addImage(layerId, img)
          }
          this.map.addSource(layerId, this.sources[layerId])
          this.map.addLayer({
            id: layerId,
            type: 'symbol',
            source: layerId,
            layout: {
              'icon-image': layerId,
              // 'icon-size': 0.9,
              'icon-anchor': 'bottom',
              'icon-allow-overlap': true
            },
            // paint: {
            //   'icon-translate': [0, 5]
            // }
          })
          this._philLoaded = true
          this._reorderLayers()
          this.map.on('click', layerId, () => {
            this.map.flyTo({ center: coords, zoom: 18 });
          });
        })
      } else {
        const source = this.map.getSource(layerId)
        if (source) {
          source.setData(data)
          this._reorderLayers()
        }
      }
    })
  }
  _hasAddLayer(l) {
    return contains(l, this.state.addLayers)
  }
  _lightningLayers () {
    const { addLayers } = this.state
    let layersToDelete = Object.keys(this.deviceLayers).filter(test(/lightning/))
    const cleanUpLayers = () => {
      if (layersToDelete.length > 0) {
        layersToDelete.forEach(this._removeLayer)
        layersToDelete.forEach(this._removeSource)
      }
    }
    if (!this._hasAddLayer('lightning')) {
      return cleanUpLayers()
    }
    const ZOOM_ICON_SWITCH = 5.7
    const HOW_MANY_LAT_BANDS = 18
    const MAX_CIRCLE_OPACITY = 0.6
    const milesToPixelsAtMaxZoom = (miles, latitude) => miles * 1609.34 / 0.075 / Math.cos(latitude * Math.PI / 180)
    const latBandVal = 90 / HOW_MANY_LAT_BANDS
    const getLayerIdForDevice = device => {
      const lat = pipe(path(['info', 'coords', 'geo', 'coordinates']), nth(1))(device)
      return 'lightning-' + Math.floor(Math.abs(lat) / latBandVal)
    }

    const getFeatureForDevice = device => {
      const lightningTime = path(['lastData', 'lightning_time'], device) || 0
      const maxTime = 1000 * 60 * 15 // 15 mins
      let lightning_opacity = ((maxTime - (Date.now() - lightningTime)) / maxTime) * MAX_CIRCLE_OPACITY 
      let lightning_distance = path(['lastData', 'lightning_distance'], device)
      if (lightning_opacity < 0) {
        lightning_opacity = 0
        lightning_distance = null
      }
      return {
        type: 'Feature',
        properties: {
          _id: device._id,
          // lightning_distance: 20,
          // lightning_opacity: 0.5,
          lightning_distance: isSomething(lightning_distance) ? lightning_distance : '',
          lightning_opacity
        },
        geometry: path(['info', 'coords', 'geo'], device)
      }
    }
    const groupedDeviceLayers = getGroupedDeviceLayers(getLayerIdForDevice, getFeatureForDevice, this.devices)

    Object.keys(groupedDeviceLayers).forEach(layerId => {
      const iconLayer = layerId + '-strike'
      // already exists
      if (this.sources[layerId]) {
        this.map.getSource(layerId).setData({
          type: 'FeatureCollection',
          features: groupedDeviceLayers[layerId]
        })

      // new layer
      } else {
        this.sources[layerId] = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: groupedDeviceLayers[layerId]
          }
        }
        const latBand = pipe(
          split('-'),
          nth(1),
          val => parseInt(val, 10)
        )(layerId)
        const middleOfLatBand = latBand * latBandVal + latBandVal / 2
        this.deviceLayers[layerId] = {
          id: layerId,
          type: 'circle',
          source: layerId,
          paint: {
            'circle-color': ['rgba', 196, 196, 196, ['get', 'lightning_opacity']],
            'circle-opacity': ['step', ['zoom'], 0, ZOOM_ICON_SWITCH, 1],
            'circle-stroke-opacity': ['step', ['zoom'], 0, ZOOM_ICON_SWITCH, 1],
            'circle-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 0, 20, ['*', ['get', 'lightning_distance'], milesToPixelsAtMaxZoom(1, middleOfLatBand)]],
            'circle-stroke-width': 3,
            'circle-stroke-color': ['rgba', 255, 255, 255, ['get', 'lightning_opacity']]
          }
        }
        this.deviceLayers[iconLayer] = {
          id: iconLayer,
          type: 'symbol',
          source: layerId,
          layout: {
            'icon-image': 'lightning',
            'icon-allow-overlap': true
          },
          paint: {
            // 'icon-opacity': ['step', ['zoom'], ['+', ['get', 'lightning_opacity'], 1 - MAX_CIRCLE_OPACITY], ZOOM_ICON_SWITCH, 0]
          }
        }
        this.map.addSource(layerId, this.sources[layerId])
        this.map.addLayer(this.deviceLayers[layerId])
        this.map.addLayer(this.deviceLayers[iconLayer])
        this.map.setFilter(layerId, ['!=', ['get', 'lightning_distance'], ''])
        this.map.setFilter(iconLayer, ['!=', ['get', 'lightning_distance'], ''])
        this.map.moveLayer(layerId)
        this.map.moveLayer(iconLayer)
      }
      layersToDelete = layersToDelete.filter(l => l !== layerId && l !== iconLayer)
    })
    cleanUpLayers()
  }
  _reorderLayers() {
    const backwardsOrder = ['webcam', 'video',  'video-enhanced-2', 'video-enhanced-1', 'social', 'alert', 'fav']
    backwardsOrder.forEach(l => {
      if (this.sources[l]) {
        this.map.moveLayer(l)
      }
    })
    if (this._pinLoaded) {
      this.map.moveLayer('pin')
    }
    if (this._philLoaded) {
      this.map.moveLayer('phil')
    }
  }
  // sets our internal devices
  // calling with nothing will simply update the visible devices
  _setDevices(devices) {
    const { device, social, socialActions, deviceActions } = this.props
    if (devices) {
      this.devices = devices
    }
    // if (device.sidebar) {
      // which of these are in view
      const lngLatBounds = this.map.getBounds()
      const llb = lngLatBounds.toArray() 
      const boundsPoly = bboxPolygon(flatten(llb))
      const visiblePosts = social.allPosts.filter(p => {
        return path(['geo'], p) && booleanIntersects(boundsPoly, p.geo)
      }) 
      socialActions.setMapVisiblePosts(visiblePosts)
      const visiblePostdeviceIds = pluck('deviceId', visiblePosts)
      deviceActions.setMapVisibleDevices(this.devices.filter(d => {
        const deviceInGeo = getDeviceGeo(d) && lngLatBounds.contains(getDeviceGeo(d).coordinates)
        return deviceInGeo || visiblePostdeviceIds.includes(d._id)
      }))
    // }
  }
  _doFetch (fetchArgs, skipFilter) {
    const { deviceActions } = this.props
    return deviceActions.fetchDevice(fetchArgs)
      .then(res => {
        const devices = path(['data'], res)
        this.setDevices((devices || []).filter(skipFilter ? identity : showDeviceOnMap), false)
        return res
      })
  }
  setDevices(devices, fitBounds = true, center = false) {
    const geos = map(getDeviceGeo, devices) 
    const coords = map(path(['coordinates']), geos) 
    this._setDevices(uniqBy(path(['_id']), concat(devices, this.devices)))
    if (fitBounds) {
      if (coords.length > 0) {
        const bounds = new mapboxgl.LngLatBounds()
        coords.forEach(coord => {
          bounds.extend(coord)
        })
        fitBounds = bounds
      } else {
        fitBounds = false
      }
    }
    if (center) {
      this.newCenter = center
    }
    this._updateDeviceDots(fitBounds)
  }
  postsSearch(args = {}) {
    const { socialActions } = this.props
    const socialArgs = Object.assign({
      expiresAt: {
        $gt: Date.now()
      },
      status: 'published'
    }, args)
    socialActions.fetchPosts(socialArgs)
      .then(posts => {
        const deviceIds = pluck('deviceId', posts.data)
        const ourDeviceIds = pluck('_id', this.devices)
        const unfetchedDeviceIds = deviceIds.filter(id => !ourDeviceIds.includes(id))
        if (unfetchedDeviceIds.length > 0) {
          const fetchGroups = splitEvery(20, unfetchedDeviceIds)
          fetchGroups.forEach($in => {
            this._doFetch({
              public: {
                $ne: null
              },
              _id: {
                $in
              }
            }, true)
          })
        }
      })
  }
  searchMap(opts = {}) {
    const { socialActions, device, deviceActions } = this.props
    const layer = this._layer()
    if (layer !== '' && !MAP_LAYER_CONFIG[layer].labelParam) {
      return // we dont show dots for this layer
    }
    const box = opts.bounds || this.map.getBounds().toArray() 
    const $publicBox = box.map(lonLat => {
      if (lonLat[0] > 180) {
        return [180, lonLat[1]]
      } else if (lonLat[0] < -180) {
        return [-180, lonLat[1]]
      }
      return lonLat
    })
    const args = {
      $publicBox,
      $limit: 100,
      rank: 2,
      skipCache: true
    }
    const zoom = this.map.getZoom()

    this._doFetch(args) // zoomed out, only fetch higly ranked
    // zoomed in, 2 fetches
    if (!zoom || zoom >= MAIN_ZOOM_BREAKPOINT) {
      this._doFetch(omit(['rank'], args))
    }
    // social dots
    if (socialActions && layer === '') {
      this.postsSearch({ $publicBox })
    }
  }
  _mapOpts() {
    return Object.assign({
      hideNavControl: false,
      noScrollZoom: false,
      padding: 50,
      paddingTop: 200,
      hidePopupValue: false,
      interactive: true,
      maxZoom: 13
    }, this.props.mapOpts)
  }
  _shouldShowDotLabel(d) {
    const { user, device } = this.props
    const { focusDevice, hoverDevice } = device
    if (!hoverDevice) return true // no hovering
    if (hoverDevice && this._hoverDevice) return true // hover on the map
    if (hoverDevice && this._shouldDotPulse(d)) return true // pulsing dot
    return false
  }
  _getFieldForDevice(d) {
    const { user, device } = this.props
    const { focusDevice, hoverDevice } = device
    const layer = this._layer() 
    const layerParam = getLayerParam(layer)
    let f = convertUnitForUser(user, layerParam, path(['lastData', layerParam], d))
    let field = ''
    if (this._shouldShowDotLabel(d)) {
      if (isSomething(f)) {
        if (layerParam === 'tempf') {
          field = f + '°'
        } else if (layerParam === 'hourlyrainin') {
          field = f + getSuffForUser(user, layerParam).replace('in/hr', '"').replace('mm/hr', 'mm').replace(' ', '')
        } else if (layerParam === 'windgustmph') {
          field = Math.round(f)
        } else {
          field = f
        }
      }
    } else {
      field = ' '
    }
    return field
  }
  _getFeatureForDevice(d) {
    const { user, device } = this.props
    const { zoom } = this.state
    const { focusDevice, hoverDevice } = device
    const properties = {
      _id: d._id,
      field: this._getFieldForDevice(d),
      focused: focusDevice && focusDevice._id === d._id,
      hovered: hoverDevice && hoverDevice._id === d._id,
      opacity: 1,
      size: 1
    }
    const layer = this._layer() 
    const layerParam = getLayerParam(layer)
    if (layerParam === 'windgustmph') {
      properties.winddir = path(['lastData', 'winddir'], d)
    }
    return {
      type: 'Feature',
      properties,
      geometry: path(['info', 'coords', 'geo'], d)
    }
  }
  _getFeatureForPost(post) {
    const { user, device } = this.props
    const { focusDevice, hoverDevice } = device
    const properties = {
      _id: post.deviceId,
      field: '🎈',
      focused: focusDevice && focusDevice._id === post.deviceId,
      hovered: hoverDevice && hoverDevice._id === post.deviceId,
      opacity: 1,
      size: 1
    }
    return {
      type: 'Feature',
      properties,
      geometry: path(['data', 'centerGeo'], post) || centerPointForGeo(post.geo)
    }
  }
  _updateDeviceDots(fitBounds) {
    const { deviceActions, user, device, social } = this.props
    const { hoverDevice } = device
    const layer = this._layer()
    const mapOpts = this._mapOpts()
    const padding = mapOpts.padding
    this._onReady(() => {
        let postsToShow = []
        let deviceIdsToRemove = []
        let socialLayers = {}
        // default layer add social
        if (layer === '') {
          postsToShow = social.allPosts.filter(postIsActive)
          deviceIdsToRemove = deviceIdsWithActivePosts(postsToShow)
          const alerts = postsToShow.filter(propEq('type', 'alert'))
          const alertFeatures = alerts.map(this._getFeatureForPost)
          const alertDeviceIds = pluck('deviceId', alerts)
          const regularPosts = postsToShow.filter(p => p.type !== 'alert' && !alertDeviceIds.includes(p.deviceId)).map(this._getFeatureForPost)
          if (regularPosts.length > 0) {
            socialLayers['social'] = regularPosts
          }
          if (alerts.length > 0) {
            socialLayers['alert'] = alertFeatures
          }
        }
        const lngLatBounds = this.map.getBounds()
        const devicesForNonSocialLayers = this.devices
          .filter(d => !deviceIdsToRemove.includes(d._id)) 
          .filter(d => {
            if (!lngLatBounds) return true
            return path(['info', 'coords', 'geo', 'coordinates'], d) && lngLatBounds.contains(path(['info', 'coords', 'geo', 'coordinates'], d))
          })
        const groupedDeviceLayers = Object.assign(
          {}, 
          getGroupedDeviceLayers(this._getLayerIdForDevice, this._getFeatureForDevice, devicesForNonSocialLayers),
          socialLayers
        )
        if (fitBounds) {
          const fitBoundsOpts = {
            padding: {
              top: mapOpts.paddingTop,
              bottom: padding,
              left: padding,
              right: padding 
            },
            maxZoom: mapOpts.maxZoom
          }
          if (this.newCenter) {
            fitBoundsOpts.center = this.newCenter
            this.setPin(this.newCenter)
            this.newCenter = null 
          }
          this.map.fitBounds(fitBounds, fitBoundsOpts)
        }
        let layersToDelete = Object.keys(this.deviceLayers).filter(pipe(test(/lightning/), not))

        const layerKeys = Object.keys(groupedDeviceLayers)
        layerKeys.forEach(layerId => {
          if (!this.map.hasImage(layerId) && !PNG_MARKERS.map(nth(0)).includes(layerId)) {
            this.map.addImage(layerId, this._getDot(layerId), { pixelRatio: 2 })
          }
          // already exists
          if (this.sources[layerId] && this.map.getSource(layerId)) {
            this.map.getSource(layerId).setData({
              type: 'FeatureCollection',
              features: this._handleHoverDevices(layerId, groupedDeviceLayers[layerId])
            })

          // new layer
          } else {
            this.sources[layerId] = {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: groupedDeviceLayers[layerId]
              }
            }
            const rotateConfig = path([layer, 'rotate'], MAP_LAYER_CONFIG)
            this.deviceLayers[layerId] = {
              id: layerId,
              type: 'symbol',
              source: layerId,
              layout: {
                'icon-image': layerId,
                'icon-allow-overlap': true,
                'text-field': '{field}',
                'text-optional': true,
                'text-anchor': 'top',
                'text-allow-overlap': false,
                // 'text-size': 27,
                'text-size': ['case', 
                    ['==', ['get', 'field'], ' '],
                    0,
                    27
                ],
                'icon-size': ['get', 'size'],
                'text-offset': [0, 0.5],
                'icon-rotate': rotateConfig || 0,
                'text-font': ['Ubuntu Medium']
              },
              paint: {
                'text-color': getTheme(user) === 'dark' ? '#fff' : '#000',
                'icon-opacity': ['get', 'opacity'] 
              }
            }
            if (/pws-/.test(layerId)) {
              this.deviceLayers[layerId].minzoom = 7.5
            } else if (this._layer() === 'radar') {
              this.deviceLayers[layerId].minzoom = 5
            }
            this.map.on('mouseleave', layerId, evt => {
              this.map.getCanvas().style.cursor = ''
              this._hoverDevice = null
              deviceActions.hoverDevice()
            })
            this.map.on('mouseover', layerId, evt => {
              const features = evt.features 
              if (!features[0]) return
              this.map.getCanvas().style.cursor = 'pointer' // Change the cursor style as a UI indicator.
              this._hoverDevice = this._getDeviceForEvt(evt) 
              deviceActions.hoverDevice(this._hoverDevice)
            })
            this.map.addSource(layerId, this.sources[layerId])
            this.map.addLayer(this.deviceLayers[layerId])
            this.map.setFilter(layerId, ['!=', ['get', 'field'], ''])
          }
          layersToDelete = layersToDelete.filter(l => l !== layerId)
        })
        layersToDelete.forEach(this._deleteLayer)
        this._mapInteractions()
        this._lightningLayers()
        // this._webcamSize()
        this._reorderLayers()
    })
  }
  _mapInteractions() {
    if (this._mapClickLoaded) return
    const { setSidebarCheck, deviceActions, onDeviceClick, user } = this.props
    this._mapClickLoaded = true
    this.map.on('click', async evt => {
      const endpoint = this._layer() && MAP_LAYER_CONFIG[this._layer()].endpoint
      if (endpoint) {
        const res = await aerisData(endpoint, {
          p: `${evt.lngLat.lat},${evt.lngLat.lng}`
        })
        const data = res.response[0]
        if (data) {
          this._popupPlus(data)
        }
      }
      const features = this.map.queryRenderedFeatures(evt.point, { layers: Object.keys(this.deviceLayers) })
      evt.features = features // _getDeviceForEvt uses this
      // this._popup(evt) // no popup for now
      if (features[0]) {
        const device = this._getDeviceForEvt(evt)
        deviceActions.focusDevice(device)
        if (setSidebarCheck) {
          deviceActions.setSidebar(setSidebarCheck(evt))
        } else {
          deviceActions.setSidebar(true)
        }
        if (onDeviceClick) {
          onDeviceClick(device, {
            layer: path(['layer', 'source'], features[0]),
            map: this
          })
        }
      }
    })
    this.map.on('mouseleave', evt => {
      this._hoverDevice = null
    })
  }
  _popupPlus(data) {
    const { user } = this.props

    const mapOpts = this._mapOpts()
    this._removePopup()
    this.popup = new mapboxgl.Popup({
      closeButton: true,
      maxWidth: '300px',
      closeOnClick: true
    })
    this.popup.on('close', () => {
      this._removePopup(true)
    })
    const coordinates = data.loc ? [data.loc.long, data.loc.lat] : data.position.location.coordinates
    
    const placeholder = document.createElement('div')

    render(<Provider store={store} >
        <DataPopup data={data} user={user} />
      </Provider>, placeholder)

    this.popup.setLngLat(coordinates)
      .setDOMContent(placeholder)

    this.popup.addTo(this.map)
    // fire layer
    if (data.perimeter && data.perimeter.polygon) {
      this.popupSource = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: data.perimeter.polygon,
            // geometry: {
            //   "coordinates": [
            //     [
            //       [
            //         -127.05258545863842,
            //         45.618902400086114
            //       ],
            //       [
            //         -127.05258545863842,
            //         30.401685210513733
            //       ],
            //       [
            //         -72.90946012999977,
            //         30.401685210513733
            //       ],
            //       [
            //         -72.90946012999977,
            //         45.618902400086114
            //       ],
            //       [
            //         -127.05258545863842,
            //         45.618902400086114
            //       ]
            //     ]
            //   ],
            //   "type": "Polygon"
            // } 
          }]
        }
      } 
      const id = POPUP_LAYER_ID 
      if (!this.map.getSource(id)) {
        this.map.addSource(id, this.popupSource)
      }
      if (!this.map.getLayer(id)) {
        this.map.addLayer({
          id,
          type: 'fill',
          source: id,
          paint: {
            'fill-color': '#AA4A44',
            'fill-opacity': 0.8
          }
        })
      }
    }
  }
  _popup(e) {
    const { onViewDashboardClick, device } = this.props
    const { mapLayer } = device
    if (!onViewDashboardClick) return

    const mapOpts = this._mapOpts()
    this._removePopup()
    if (!e.features || !e.features[0]) return
    if (['social', 'webcam'].includes(e.features[0].layer.id)) return
    const onMouseLeave = () => this.popup.remove()
    const currentDevice = this._getDeviceForEvt(e) 
    let closeOnClick = true 
    let closeButton = false
    if (e.type === 'touchend') {
      this.isTouch = true
      // closeOnClick = false
      closeButton = true
      if (!currentDevice) {
        onMouseLeave()
      }
    }
    this.popup = new mapboxgl.Popup({
      closeButton,
      maxWidth: '300px',
      closeOnClick
    })
    var coordinates = e.features[0].geometry.coordinates.slice()
    
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }
    const placeholder = document.createElement('div')

    render(<Provider store={store} >
        <DevicePopup 
          onMouseLeave={this.isTouch ? null : onMouseLeave} 
          currentDevice={currentDevice} 
          layerParam={mapOpts.hidePopupValue ? false : getLayerParam(mapLayer)}
          onClick={() => {
            if (onViewDashboardClick) {
              onViewDashboardClick(currentDevice)
            }
          }}
        />
      </Provider>, placeholder)

    // Populate the popup and set its coordinates
    // based on the feature found.
    this.popup.setLngLat(coordinates)
      .setDOMContent(placeholder)

    this.popup.addTo(this.map)
  }
  _removePopup(skipPopup) {
    if (!skipPopup && this.popup) {
      this.popup.remove()
      this.popup = null
    }
    if (this.popupSource) {
      this.map.removeLayer(POPUP_LAYER_ID)
      this.map.removeSource(POPUP_LAYER_ID)
      this.popupSource = null
    }
  }

  _handleHoverDevices(layerId, features) {
    const animatedLayers = ['webcam', 'wind', 'social', 'alert', 'fav', 'video', 'video-enhanced-1',  'video-enhanced-2']
    if (!animatedLayers.includes(layerId)) return features
    const animatedDevice = find(this._shouldDotPulse, this.devices)
    if (!animatedDevice) return features
    return features.map(d => {
      const { properties } = d
      if (properties._id === animatedDevice._id) {
        d.properties.size = 1.4
      } else {
        d.properties.size = 1 
      }
      d.properties.field = this._getFieldForDevice(find(propEq('_id', d.properties._id), this.devices))
      return d
    }) 
  }
  _removeLayer(lId) {
    if (this.map.getLayer(lId)) {
      this.map.removeLayer(lId)
      delete this.deviceLayers[lId]
    }
  }
  _removeSource(lId) {
    if (this.map.getSource(lId)) {
      this.map.removeSource(lId)
      delete this.sources[lId]
    }
  }
  _deleteLayer(lId) {
    this._removeLayer(lId)
    this._removeSource(lId)
  }
  /**
   *  Does not immediately set center (use this.map.setCenter for that), this will
   * re-center after adding new devices
   * @param {GeoJson coordinates} coords 
   */
  setCenter(coords) {
    this.newCenter = coords
  }
  _getDeviceForEvt(e) {
    if (e) {
      return find(propEq('_id', e.features[0].properties._id), this.devices)
    }
  }
  _getDot(layerId) {
    let size = 100
    const _map = this.map
    let [type, color, active] = layerId.split('-')
    const official = type === 'official'
    const pulsing = !!active

    return {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),
      
      onAdd: function() {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },
      
      render: function() {
        var duration = 2000
        var t = (performance.now() % duration) / duration

        size = pulsing ? 100 : 75
        
        var radius = size / 2 * 0.3;
        var outerRadius = radius + 5;
        var context = this.context;

        const rgbStr = `${hexToRgb(color).r},${hexToRgb(color).g},${hexToRgb(color).b}`
        
        if (official || pulsing) {
          // draw outer circle
          context.clearRect(0, 0, this.width, this.height)
          context.beginPath()
          if (pulsing) {
            outerRadius = (size / 2) * 0.7 * t + radius
            context.fillStyle = `rgba(${rgbStr},` + (1 - t) + ')'
          } else {
            context.fillStyle = `#000000`
          }
          context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
          context.fill();
        }
        
        // draw inner circle
        const isDefault = color === 'transparent'
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = isDefault ? (official ? '#7c7d88' : color) : '#' + color;
        context.strokeStyle = isDefault ? 'rgba(0, 0, 25, 0.5)' : 'rgba(255, 255, 255, 1)';
        context.lineWidth = 4;
        context.fill();
        context.stroke();
        
        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data
        
        if (pulsing) {
          _map.triggerRepaint()
        // static - only redraw once
        } else if (this.drawn) {
          return false
        }
        this.drawn = true
        return true
      }
    }
  }
  _aerisTime(t, now) {
    if (!now) {
      now = this.state.now
    }
    return parseInt((t - now.valueOf()) / 1000, 10)
  }
  _aerisLayers(prevLayer) {
    const layer = prevLayer || this._layer() 
    const layers = (path([layer, 'aerisLayers'], MAP_LAYER_CONFIG) || []).slice(0)
    if (this._hasAddLayer('lightning')) {
      layers.push(`lightning-strikes-15m-icons`)
    }
    return layers
  }
  _addVendorLayers() {
    const { zoom } = this.state
    const layerConfig = MAP_LAYER_CONFIG[this._layer()]
    if (!layerConfig || !this.aerisMap) return
    if (layerConfig.aerisModule) {
      this.aerisMap.addModule(layerConfig.aerisModule)
      this.lastModule = layerConfig.aerisModule
    } else if (layerConfig.accuweather) {
      const ACCUWEATHER_KEY = 'apikey=34d63eadb3384b4b86e1f5a5741f9820'
      if (this._layer() === 'radar' && !this.state.radarFrames) {
        const toplat = parseInt(this.map.getBounds().getNorth())
        fetch(`https://api.accuweather.com/maps/v1/radar/globalSIR/preferred_box_frames?${ACCUWEATHER_KEY}&toplat=${toplat}&bottomlat=${parseInt(this.map.getBounds().getSouth())}&rightlon=${parseInt(this.map.getBounds().getWest())}&leftlon=${parseInt(this.map.getBounds().getEast())}&zoom=2&attribute=true`)
          .then(res => res.json())
          .then( data => {
            const radarFrames = (data.frames || [])
              .sort((a, b) => (new Date(b)).getTime() - (new Date(a)).getTime())
            radarFrames.forEach((frame, i) => {
              if (!this.map.getSource(`accuweather-${toTime(frame)}`)) {
                this.map.addSource(`accuweather-${toTime(frame)}`, {
                  type: 'raster',
                  tiles: [`https://api.accuweather.com/maps/v1/radar/globalSIR/zxy/${frame}/{z}/{x}/{y}.png?${ACCUWEATHER_KEY}`],
                  tileSize: 256
                })
              }
            })
            this.setState({
              radarFrames,
              animationNow: (new Date(radarFrames[0])).getTime(),
              animationTime: (new Date(radarFrames[0])).getTime()
            })
          })
          .catch(err => {
            console.error(err)
          })
      }
      if (this._layer() === 'wind' && !this.state.windFrames) {
        fetch(`https://api.accuweather.com/maps/v1/models/gfs/preferred_product_frames?products=26-1020&${ACCUWEATHER_KEY}`)
          .then((response) => response.json())
          .then((responseJSON) => {
            const respFrames = responseJSON.frames.filter((time, i) => i <= 24);
            let frames = [];
            // For now we're just going to use the first frame
            frames.push({
                url: `${responseJSON.url}custom?topmerc=3.1415&bottommerc=-3.1415&rightmerc=3.1415&leftmerc=-3.1415&imgheight=1440&imgwidth=1440&display_mode=10&blend=1&display_products=26-1020&frametime=${respFrames[0]}&${ACCUWEATHER_KEY}`,
                timestamp: respFrames[0],
                DateTime: new Date(respFrames[0]).toUTCString(),
            });

            // Create the wind speed layer
            const windSpeedLayer = new WindSpeedLayer('windspeed', frames);
            // Toggle this to false if you only want particles
            windSpeedLayer.enabled = true;
            windSpeedLayer.opacity = 0.7;

            // Create the wind particle layer
            const windParticleLayer = new WindParticleLayer(
                'windparticles',
                frames,
                document.documentElement.clientWidth
            );
            // For now, no animation
            // windParticleLayer.frameDuration = 750;
            // Toggle this to false if you don't want the particles
            windParticleLayer.enabled = true;
            // Toggle this to false if you only want particles
            windParticleLayer.windEnabled = true;

            this.map.addLayer(windSpeedLayer, getFirstSymbolLayer(this.map));
            this.map.addLayer(windParticleLayer, getFirstSymbolLayer(this.map));
            this.setState({
              windFrames: frames
            })
        });

        // Add a new layer using the source you've just defined
      } else if (!this.map.getLayer('accuweather')) {
        if (!this.map.getSource('accuweather')) {
          this.map.addSource('accuweather', {
            type: 'raster',
            tiles: layerConfig.accuweather.map(u => {
              u = u.replace('DATE', moment().utc().startOf('hour').format('YYYY-MM-DDTHH:mm:ss[Z]'))
              u += (/\?/.test(u) ? '&' : '?') + ACCUWEATHER_KEY
              return u
            }),
            tileSize: 256
          });
        }
        this.map.addLayer({
          id: 'accuweather',
          type: 'raster',
          source: 'accuweather',
          paint: {
            // Add layer-specific paint properties here if needed
            'raster-opacity': 0.5
          }
        });
      }
      if (this._aerisLayers().length > 0) {
        this.aerisMap.addLayers(this._aerisLayers())
      }
      this._reorderLayers()

    // aerisLayers
    } else {
      const from = -ANIMATION_HOURS * 3600 // two hours ago
      const to = 0
      this.setState({
        animationNow: Date.now(),
        animationTime: Date.now()
      })
      this.aerisMap.addLayers(this._aerisLayers(), {
        timeline: {
          from,
          to,
          intervals: 12
        }
      })
    }
    if (layerConfig.minZoom && this.map.getZoom() > layerConfig.minZoom) {
      this.map.setZoom(layerConfig.minZoom)
      this.setState({
        zoom: layerConfig.minZoom
      })
    }
    if (layerConfig.markerClick) {
      this.aerisMap.on('marker:click', layerConfig.markerClick)
    }
  }
  _removeVendorLayers(prevLayer) {
    const { accuweatherLayerId } = this.state
    if (this.lastModule) {
      const source = this.aerisMap.getSourceForId(this.lastModule.id)
      this.lastModule = null
      if (source) {
        this.aerisMap.removeSource(source)
      }
      this.aerisMap.off('marker:click', MAP_LAYER_CONFIG['covid-19'].markerClick) // hack
    }
    if (this.aerisMap) {
      this.aerisMap.removeLayers(this._aerisLayers(prevLayer))
    }
    if(this.map.getLayer('accuweather')) {
      this.map.removeLayer('accuweather')
      this.map.removeSource('accuweather')
      this.setState({
        radarFrames: false,
        accuweatherLayerId: null
      })
    }
    if (this.map.getLayer(accuweatherLayerId)) {
      this.map.removeLayer('accuweather')
      this.map.removeSource('accuweather')
      this.map.removeLayer(accuweatherLayerId)
      this.setState({
        radarFrames: false,
        accuweatherLayerId: null
      })
    }
    if (this.map.getLayer('windparticles')) {
      this.map.removeLayer('windparticles')
      this.map.removeLayer('windspeed')
      this.setState({
        windFrames: false
      })
    }
  }
  _mapMoveEnd(evt) {
    const { userInteracted } = this.state
    // if (this.isAnimating()) {
    //   this.stopAnimation()
    // }
    this.setState({
      zoom: this.map.getZoom()
    })
  }
  _mapMoveStart() {
    if (!this.isAnimating()) {
      // this._removeVendorLayers()
    }
  }
  _addMarkerImage(layer, img) {
      this.images[layer] = this.images[layer] || []
      this.images[layer].push(img)
  }
  _loadImages() {
    return Promise.all(PNG_MARKERS.map(arr => {
      const image = arr[0]
      const icon = arr[1]
      return new Promise((resolve, reject) => {
        if (!this.map.hasImage(image)) {
          this.map.loadImage(MARKER_IMG_BASE + '/' + icon + '.png', (err, img) => {
            if (err) throw err
            if (!this.map.hasImage(image)) {
              this.map.addImage(image, img)
            } else {
              resolve()
            }
          })
        }
        resolve()
      })
    }))
  }
  _onMapLoad() {
    const { coords, onMapLoad } = this.props
    if (!this._mounted) return
    if (!aeris) return

    this._doPhil()
    this._loadImages()
      .then(() => {
        this.onReady.forEach(fn => fn())
        this.onReadyCalled = true
      })

    aeris.views()
      .then(views => {
        this.aerisMap = new views.InteractiveMap(this.map, coords ? {
          center: {
            lat: coords[1],
            lon: coords[0]
          },
          zoom: getStorage('lastZoom')
        } : {})
        // this.aerisMap.on('ready', () => {
        // })
        this.aerisMap.on('move:end', this._mapMoveEnd.bind(this))
        this.aerisMap.on('move:start', this._mapMoveStart.bind(this))
        this.aerisMap.on('timeline:change', this._timelineChange.bind(this))
        if (onMapLoad) {
          onMapLoad(this.map)
        }
        this.map.resize()
      })
  }
  _mapStyle() {
    return getTheme(this.props.user) === 'light' ?  'https://api.maptiler.com/maps/d9324f85-e435-4500-91ce-acde2246c42d/style.json?key=0b7htcdKMAS3HuYedMi7': 'https://api.maptiler.com/maps/ea75d980-caba-4680-8ad8-13b7d11edd52/style.json?key=0b7htcdKMAS3HuYedMi7'
    // return getTheme(this.props.user) === 'light' ?  'https://api.maptiler.com/maps/d9324f85-e435-4500-91ce-acde2246c42d/style.json?key=0b7htcdKMAS3HuYedMi7': 'https://api.maptiler.com/maps/b35e471b-b6ec-43b5-b1ea-1a8bcd68771c/style.json?key=0b7htcdKMAS3HuYedMi7'
    // return getTheme(this.props.user) === 'light' ? 'mapbox://styles/ambientweather/ck2cfph860g191co6rtk4jt0y' : 'mapbox://styles/ambientweather/ckcm86nqg1dj11io600558mv0'
  }
  componentDidMount() {
    const { user, onRef, coords, noScrollZoom } = this.props
    if (noMaps()) return
    const lastLocationSearch = getStorage('lastLocationSearch')
    let center = [-102.2494, 40.674533]
    if (coords) {
      center = coords
    } else if (lastLocationSearch) {
      center = [lastLocationSearch.coords.lon, lastLocationSearch.coords.lat]
    }
    this._mounted = true
    const mapOpts = this._mapOpts()
    // dont load map if we are reloading
    if (!isDev() && !isCordova() && window.location.protocol === 'http:') {
      return
    }
    if (!window.mapboxgl) return
    const map = new mapboxgl.Map({
      container: this.id,
      // Cordova problem here
      // style: 'https://api.maptiler.com/maps/basic/style.json?key=HlGBcDDTBeCFPZZ3Bh3E', // for test, replaces style AND accessToken
      style: this._mapStyle(),
      accessToken: 'pk.eyJ1IjoiYW1iaWVudHdlYXRoZXIiLCJhIjoiY2syY2ZvaTd1MDJ0dzNsbzRzY3F4YWZhMiJ9.a7DVxlk_9Yf9157nrlE_3A',
      center,
      zoom: getStorage('lastZoom') || 4,
      attributionControl: false,
      maxZoom: 18,
      logoPosition: 'bottom-right',
      scrollZoom: !mapOpts.noScrollZoom,
      interactive: mapOpts.interactive,
      transition: {
        duration: 300,
        delay: 0
      }
    })
    this.map = map
    this.map.addControl(new mapboxgl.AttributionControl({
      customAttribution: `© ${getLegalName()}, ${moment().format('YYYY')}. All rights reserved. | <a href=${getThemeObj().helpUrlBase + "legend/"} rel="noopener" target="_blank" class='map-legend'>Map Legend</a>`
    }), 'bottom-left')
    if (!mapOpts.hideNavControl) {
      this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    }
    console.log('loaded****');
    this.map.on('load', this._onMapLoad.bind(this))
    this.map.on('moveend', () => {
      this.searchMap()
    })
    this.map.on('zoomend', evt => {
      if (evt.originalEvent || evt.doSearch) {
        this.searchMap()
      }
      if (evt.searchDelay) {
        this._searchDelayTout = clearTimeout(this._searchDelayTout)
        this._searchDelayTout = setTimeout(this.searchMap, evt.searchDelay)
      }
    })
    if (onRef) {
      onRef(this)
    }
  }
  componentWillUnmount() {
    this._mounted = false
    const { onRef } = this.props
    if (onRef) {
      onRef(undefined)
    }
  }
  _goToTime(animationTime) {
    const { radarFrames, accuweatherLayerId } = this.state;
  
  
    const closestFrame = radarFrames.find(f => animationTime >= toTime(f));
    const newLayerId = `accuweather-${toTime(closestFrame)}`;

    this.setState({
      animationTime,
      accuweatherLayerId: newLayerId
    });
  
    // Add the new layer with initial opacity 0 and visibility none
    this.map.addLayer({
      id: newLayerId,
      type: 'raster',
      source: newLayerId,
      layout: {
        'visibility': 'none'  // Initially set to none
      },
      paint: {
        'raster-opacity': 0  // Start fully transparent
      }
    });

    // Use the 'idle' event to ensure the map and layers are fully loaded
    this.map.once('idle', () => {
      // Make the new layer visible
      this.map.setLayoutProperty(newLayerId, 'visibility', 'visible');
      
      // Smoothly transition the opacity of the new layer to 1
      let opacity = 0;
      const topOpacity = 0.6;
      const interval = setInterval(() => {
        opacity += 0.05; // Increment opacity
        if (opacity > topOpacity) {
          opacity = topOpacity;
          clearInterval(interval); // Stop the interval when opacity reaches 1

          // Remove the old layer(s) after the transition is complete
          if (this.map.getLayer('accuweather')) {
            this.map.removeLayer('accuweather');
          }
          if (this.map.getLayer(accuweatherLayerId)) {
            this.map.removeLayer(accuweatherLayerId);
          }
        }
        this.map.setPaintProperty(accuweatherLayerId, 'raster-opacity', topOpacity - opacity);
        this.map.setPaintProperty(newLayerId, 'raster-opacity', opacity);
      }, 30); // Adjust the speed of the transition by changing the interval time
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (!this.map || !this.props.common.online) return

    const { playing } = this.state
    const mapLayerChanged = pathsChanged(this.props, prevProps, [['device', 'mapLayer']])
    if (mapLayerChanged) {
      this.stopAnimation()
      this._removeVendorLayers(prevProps.device.mapLayer)
      this._addVendorLayers()
    }
    // new focusDevice
    if (pathsChanged(this.props, prevProps, [['device', 'focusDevice']]) && this.props.focusDevice) {
      const coords = getDeviceGeo(this.props.device.focusDevice).coordinates 
      // remember this spot - mimic google maps format
      setStorage('lastLocationSearch', formatCoords({}, coords))
      this.setPin(coords)
      this.map.flyTo({ center: coords })
    }
    
    if (pathsChanged(this.props, prevProps, [['user', 'userUnits'], ['device', 'hoverDevice'], ['social', 'allPosts']])  // user units changed or marker is hovered or posts loaded
      || mapLayerChanged
      || pathsChanged(this.state, prevState, ['addLayers']) // additional layers changed
    ) {
      this._updateDeviceDots()
    }
    // remember lastZoom
    if (pathsChanged(this.state, prevState, ['zoom'])) {
      const { deviceActions } = this.props
      setStorage('lastZoom', this.state.zoom)
      deviceActions.setThing('mapZoom', this.state.zoom)
    }
    // keep dots up to date with lastest data
    if (pathsChanged(this.props, prevProps, [['device', 'deviceCache'], ['device', 'devices']])) {
      const { devices, deviceCache } = this.props.device
      this._setDevices(this.devices.map(d => {
        if (devices) {
          const myDevice = find(propEq('_id', d._id), devices)
          if (myDevice) {
            return myDevice
          }
        }
        if (deviceCache[d._id]) {
          return deviceCache[d._id]
        }
        return d
      }))
      this._updateDeviceDots()
    }
    // sidebar opened or closed
    if (pathsChanged(this.props, prevProps, [['device', 'sidebar']])) {
      // keep in sync with css transition time in OutsideBar.less
      const t = 200 // 0.2s
      setTimeout(() => {
        if (this.map) {
          this.map.resize()
        }
        this._setDevices()
      }, t * 1.25)
    }

    // theme changed
    if (getTheme(this.props.user) !== getTheme(prevProps.user)) {
      this.map.setStyle(this._mapStyle())
    }

    // coords changed, center of map
    if (pathsChanged(this.props, prevProps, [['coords']]) && this.props.coords) {
      this.setPin(this.props.coords)
      this.map.jumpTo({ center: this.props.coords, zoom: 14 })
    }

    // mapBounds
    if (pathsChanged(this.props, prevProps, [['mapBounds']]) && this.props.mapBounds) {
      const tryBounds = () => {
        const lngLatBounds = this.map.getBounds()
        try {
          if (!lngLatBounds.contains(this.props.mapBounds[0]) || !lngLatBounds.contains(this.props.mapBounds[1])) {
            this.map.zoomOut({ duration: 100 }, { searchDelay: 140 })
            setTimeout(tryBounds, 110)
          }
        } catch (err) {
          console.log(err)
        }
      }
      tryBounds()
      // this.map.fitBounds(this.props.mapBounds)
      // this.searchMap({ bounds: this.props.mapBounds })
    }

  }
  startAnimation() {
    clearInterval(this._animationInt)
    const decFrame = () => {
      const { radarFrames, animationTime } = this.state
      const currentI = radarFrames.findIndex(f => animationTime >= toTime(f))
      let newIndex = currentI - 1
      if (newIndex < 0) {
        newIndex = radarFrames.length - 1
      }
      this.setState({
        playing: true
      })
      this._goToTime(toTime(radarFrames[newIndex]))
    }
    decFrame()
    this._animationInt = setInterval(decFrame, ANIMATION_FRAME_DURATION)
  }
  stopAnimation() {
    clearInterval(this._animationInt)
    this.setState({
      playing: false 
    })
  }
  isAnimating() {
    return this.state.playing
  }
  _timelineChange(evt) {
    const { time, offset } = evt.data
    if (offset % 600000 === 0) {
      this.setState({
        animationTime: time,
        animationOffset: offset
      })
    }
  }
  _dragging() {
    this.stopAnimation()
    this.setState({
      dragging: true
    })
  }
  _notDragging(){
    const { radarFrames, animationTime } = this.state
    this.setState({
      dragging: false,
      animationTime: toTime(radarFrames.find(f => animationTime >= toTime(f)))
    })
  }
  _timeline() {
    const { radarFrames, animationNow, animationTime, animationOffset, dragging } = this.state
    if (!radarFrames || this._layer() !== 'radar') return null

    const now = animationNow
    const maxValue = animationNow 
    const value = animationTime
    const start = toTime(last(radarFrames))
    const nowLabel = moment(now).format('h:mma')
    return <div className={classNames('animation-timeline', { 
      dragging: dragging || animationOffset
    })}>
        <InputRange
          step={1000 * 60 * 15}
          minValue={start}
          maxValue={maxValue}
          maxLabel={nowLabel}
          value={value}
          formatLabel={t => {
            const v = toTime(radarFrames.find(f => t >= toTime(f))) 
            if (v === now) {
              return nowLabel
            }
            return moment(v).format('h:mma')
          }}
          onChange={this._goToTime.bind(this)}
          onChangeStart={this._dragging.bind(this)}
          onChangeComplete={this._notDragging.bind(this)}
        />
    </div>
  }
  addLayerToggle (addLayer) {
    this.stopAnimation()
    this._removeVendorLayers()
    if (!this._hasAddLayer(addLayer)) {
      trackEvent('map', 'layer', addLayer)
    }
    this.setState({
      addLayers: toggleArr(addLayer, this.state.addLayers)
    }, this._addVendorLayers.bind(this))
  }
  layerToggle(l, userInteracted) {
    const { deviceActions, device } = this.props
    const layer = this._layer() 
    this.stopAnimation()
    this._removeVendorLayers()
    this._removePopup()
    if (l !== layer) {
      if (layer !== '') {
        trackEvent('map', 'layer', layer)
      }
      deviceActions.setThing('mapLayer', l)
    } else {
      deviceActions.setThing('mapLayer', '')
    }
    this.setState({
      userInteracted,
      addLayers: [],
      plusOpen: false
    })
  }
  _plusBtn() {
    const { plusOpen } = this.state
    const { user, userActions } = this.props
    let flyout
    const plusLayers = Object.keys(MAP_LAYER_CONFIG).filter(l => path([l, 'type'], MAP_LAYER_CONFIG) === 'plus')
    if (plusOpen) {
      flyout = (
        <div className='plus-flyout'>
          {plusLayers.map(l => {
            return <ProtectedLink
              onClick={() => {
                if (!isPlus(user)) {
                  userActions.doModal({
                    type: 'plus-subscription',
                    data: {
                      message: 'These layers are only available to AWN+ subscribers.'
                    }
                  })
                  return
                }
                let close = false
                if (!plusLayers.includes(this._layer())) {
                  close = true
                }
                this.layerToggle(l, true)
                if (close) {
                  this.setState({ plusOpen: false })
                }
              }}
              className={classNames(`plus-${l}`, { active: this._layer() === l })}
              key={l}
            >{MAP_LAYER_CONFIG[l].title}</ProtectedLink>
          })}
        </div>
      )
    }
    const onClick = () => {
      if (!plusOpen) {
        this.layerToggle('', true)
      }
      this.setState({ plusOpen: !plusOpen })
    }
    return (
      <div className='plus-layers'>
        {flyout}
        <a onClick={onClick} className={classNames('plus main-btn', { active: plusLayers.includes(this._layer()) }, `plus-` + this._layer())} />
      </div>
    )
  }
  _legend() {
    const { userActions } = this.props
    const layer = this._layer()
    if (!MAP_LEGENDS[layer]) {
      return null
    }
    const data = <MapLegend type={layer} />
    return (
      <a
        className='map-legend-btn'
        onClick={() => {
          userActions.doModal({
            type: 'component',
            data
          })
        }}
      >{data}</a>
    )
  }
  _buttons() {
    const { addLayers, zoom, playing, plusOpen, radarFrames } = this.state
    const { buttons, device, deviceActions, setSidebarCheck } = this.props
    const { mapVisibleDevices } = device
    const layer = this._layer()

    const btn = l => <a title={l.replace('-', '')} onClick={() => this.layerToggle(l, true)} className={classNames(l, { active: layer === l }, 'main-btn')} key={l}>{l.replace('-', ' ')}</a>

    const findDevice = (prop, compFn) => {
      const devicesWithProp = mapVisibleDevices.filter(d => isSomething(path(['lastData', prop], d)))
      return devicesWithProp.reduce((acc, d) => {
        if (!acc) return d
        if (compFn(path(['lastData', prop], d), path(['lastData', prop], acc))) {
          return d
        }
        return acc
      }, null)
    }
    const maxMinDeviceLink = (label, prop, compFn) => {
      const d = findDevice(prop, compFn)
      return (
        <a key={label + prop} 
          onMouseLeave={() => {
            deviceActions.hoverDevice()
          }}
          onMouseEnter={() => {
            deviceActions.hoverDevice(d)
          }}
          onClick={() => {
            deviceActions.focusDevice(d)
            deviceActions.hoverDevice(d)
            if (!setSidebarCheck) {
              deviceActions.setSidebar(true)
            }
          }}
        >
          <span className='label'>{label}</span>
          <FormattedDataPoint row={path(['lastData'], d)} type={prop} />
        </a>
      )
    }
    const addBtnsConfig = {
      radar: [
        (radarFrames && <a key="play" className={classNames('play sub', { active: this.isAnimating() })} onClick={() => {
          if (playing) {
            this.stopAnimation()
          } else {
            this.startAnimation()
          }
        }}></a>),
        <a key="lightning" className={classNames('lightning sub', { active: this._hasAddLayer('lightning') })} onClick={() => this.addLayerToggle('lightning')}></a>,
        <div className='high-low' key='rain-high-low'>
          {maxMinDeviceLink('Evt Max', 'eventrainin', gt)}
          {maxMinDeviceLink('Day Max', 'dailyrainin', gt)}
        </div>
      ],
      wind: [
        <div className='high-low' key='wind-high-low'>
          {maxMinDeviceLink('Max Gust', 'windgustmph', gt)}
          {maxMinDeviceLink('Max Wind', 'windspeedmph', gt)}
        </div>
      ],
      temp: [
        <div className='high-low' key='temp-high-low'>
          {maxMinDeviceLink('Max', 'tempf', gt)}
          {maxMinDeviceLink('Min', 'tempf', lt)}
        </div>
      ]
    }

    return <div className="layer-btns">
      {Object.keys(MAP_LAYER_CONFIG).map(l => {
        if (path([l, 'type'], MAP_LAYER_CONFIG) !== 'main') return null
        // on active radar layer show play button
        if (addBtnsConfig[l]) {
          return <div className={classNames("btn-wrap", { active: l === layer }, l)} key={`btn-wrap-${l}`}>{btn(l)}{addBtnsConfig[l]}</div>
        }
        return btn(l)
      })}
      {this._plusBtn()}
      {buttons}
    </div>
  }

  render() {
    const { height, zoom } = this.state
    return (
      <div ref={ref => this.ref = ref} className={classNames('component-map', this._layer())}>
        <div className="map" id={this.id}></div>
        {this._buttons()}
        {this._timeline()}
        {this._legend()}
      </div>
    )
  }
}

export default bindAllActions(Map)
Map.displayName = 'Map'
