import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { prop, contains, path } from 'ramda'
import { clear } from 'idb-keyval'
// import bindUserActions from './redux/bindUserActions';
import bindAllActions from '../../common/bindAllActions'
import { isAdmin, ucFirst, getUserSetting, guid, goodEmail, isApp, isLoggedIn } from '../../common/ambient'
import { isPlus } from '../payment'
import PasswordConfirmInputs from '../../components/PasswordConfirmInputs'
import AwnSwitch from '../common/AwnSwitch'
import {
  Loader,
  ImageUploadLink,
  AmbRadio,
  QuickLocation
} from '../../components/'
import {
  Avatar,
  ApiKeys,
  UsernameEdit,
  DeleteAccount,
  Settings
} from './'
import ManageSubscriptions from '../payment/ManageSubscriptions'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import MyTimeline from '../social/MyTimeline'

class Account extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      emailEdit: false,
      email: '',
      passEdit: false,
      password: false
    }
    this.tabs = {
      profile: 'My profile',
      units: 'Units',
      support: 'Support',
      subscriptions: 'Subscriptions',
      keys: 'API Keys',
      suggestions: 'Suggestion Box'
    }
  }

  componentDidMount () {
    (function (m, a, v, e, n, o, i, d) { n = m.createElement(a); n.async = true; n.src = 'https://app.mavenoid.com/embedded/embedded.js'; o = m.getElementsByTagName(a)[0]; o.parentNode.insertBefore(n, o); v[e] = v[e] || []   })(document, 'script', window, 'mavenoid')
  }

  componentWillUnmount () {
    window.mavenoid.push({
      event: 'assistant-unmount'
    })
  }

  componentDidUpdate () {
    const { user } = this.props
    const { userChecked } = user
    if (userChecked && !isLoggedIn(user)) {
      this.props.history.push('/welcome')
    }
  }

  render () {
    const { user, userActions, match, socialActions } = this.props
    const { password, passEdit, emailEdit, email } = this.state
    const { patchPending } = user
    const { tab = 'profile' } = match.params || {}
    const uploadingAvatar = patchPending && contains('image', patchPending)
    const message = []
    if (path(['info', 'confirmKey'], user)) {
      const link = user.sendConfirmationSucceeded
        ? <b>Confirmation Sent!</b>
        : (
          <a
            className='btn btn-primary'
            onClick={() => {
              userActions.sendConfirmation({ user: user.info._id, confirmKey: true })
            }}
          >Resend
          </a>
          )
      message.push(
        <span key={1} className='warning'>
          <span>{user.info.email} is not yet confirmed. </span>
          {link}
        </span>
      )
    } else {
      message.push(
        <span key={1}>{user.info.email}</span>
      )
    }
    message.push(
      <a
        key={3}
        className='edit'
        onClick={() => this.setState({ emailEdit: true })}
      >Edit
      </a>
    )
    const emailForm = (
      <form
        onSubmit={(evt) => {
          evt.preventDefault()
          userActions.patch(user.info._id, { email, confirmKey: guid() })
            .then(() => {
              userActions.sendConfirmation({ user: user.info._id, confirmKey: true })
              this.setState({
                email: '',
                emailEdit: false
              })
            })
        }}
      >
        <input
          type='email'
          value={email}
          placeholder='New Email'
          onChange={(evt) => this.setState({ email: evt.target.value.toLowerCase() })}
        />
        <div className='mt'>
          <input type='submit' className='btn btn-primary' value='Save' disabled={!goodEmail(email)} />
          <a className='btn btn-default' onClick={() => this.setState({ emailEdit: false, email: '' })}>Cancel</a>
        </div>
      </form>
    )

    const passForm = (
      <form
        onSubmit={(evt) => {
          evt.preventDefault()
          if (password) {
            userActions.patch(user.info._id, { password })
              .then(() => this.setState({ passEdit: false }))
          }
        }}
      >
        <PasswordConfirmInputs onChange={info => this.setState({ password: info.password })} />
        <div className='mt'>
          <input disabled={!password} type='submit' className='btn btn-primary' value='Save' />
          <a className='btn btn-default' onClick={() => this.setState({ passEdit: false })}>Cancel</a>
        </div>
      </form>
    )
    const passMessage = (
      <div>
        ***
        <a
          className='edit'
          onClick={() => this.setState({ passEdit: true })}
        >Edit
        </a>
      </div>
    )

    const avatarEdit = (
      <ImageUploadLink
        className='edit'
        pickerOptions={{
          transformations: {
            crop: {
              aspectRatio: 1,
              force: true
            }
          }
        }}
        onSuccess={image => {
          userActions.patch(user.info._id, { image })
        }}
      >Edit
      </ImageUploadLink>
    )
    const themeSetting = getUserSetting('theme')(user) || {}
    const themes = ['light', 'dark', 'auto'].map(theme => {
      return (
        <AmbRadio
          key={theme}
          label={theme === 'auto' ? 'Automatically change with time of day' : `${ucFirst(theme)} Mode`}
          selected={themeSetting.theme === theme || (!themeSetting.theme && theme === 'light')}
          onClick={() => {
            userActions.updateSetting('theme', { theme })
          }}
        />
      )
    })
    if (themeSetting.theme === 'auto') {
      themes.push(
        <div key='location' className='choose-location'>
          <h5>My Location</h5>
          <QuickLocation
            location={themeSetting.location} onChange={location => {
              userActions.updateSetting('theme', { location })
            }}
          />
        </div>
      )
    }
    const appStart = getUserSetting('appStart')(user) || {}
    let rwContact = null
    const contactPhone = path(['info', 'settings', 'info', 'phone'], user)
    if (contactPhone || path(['info', 'settings', 'home'], user) === 'rw') {
      rwContact = (
        <>
          <h3>Contact Phone</h3>
          <input
            type='text'
            value={contactPhone}
            placeholder='Contact Phone'
            onChange={(evt) => userActions.updateSetting('info', Object.assign({}, getUserSetting('info')(user), { phone: evt.target.value.toLowerCase() }))}
          />
        </>
      )
    }
    let adGraph = null
    if (isPlus(user)) {
      const dataCache = getUserSetting('dataCache')(user) || {}
      adGraph = (
        <div className='block'>
          <h3>Advanced Graphing</h3>
          <div className='line'>
            <AwnSwitch
              checked={dataCache.enabled}
              onChange={val => {
                userActions.updateSetting('dataCache', Object.assign({}, dataCache, { enabled: val }))
                if (!val) {
                  clear()
                }
              }}
            />
            <span>
              In-Browser Data Cacheing (experimental)
            </span>
          </div>
          <p className='mt'>
            This feature will store your data in your browser for faster graphing.
            {dataCache.enabled && (
              <span>
                &nbsp;If you believe your cached historical data is incorrect, you can clear it by <a onClick={() => clear()}>clicking here</a>.
              </span>
            )}
          </p>
        </div>
      )
    }
    let show = (
      <>
        <div className='profile-wrap'>
          <div className='block avatar-info'>
            <h3>Avatar</h3>
            <Avatar />
            {uploadingAvatar ? <Loader /> : avatarEdit}
            <a className='logout' href='#' onClick={() => userActions.logoutUser()}><i className='glyphicon glyphicon-off' /> Logout</a>
          </div>
          <div className='block user-info'>
            <h3>Email</h3>
            {emailEdit ? emailForm : message}
            <p />
            <h3>Password</h3>
            {passEdit ? passForm : passMessage}
            <h3>Handle</h3>
            <UsernameEdit />
            {rwContact}
          </div>
        </div>
        <div className='block theme'>
          <h3>Theme</h3>
          {themes}
          {isApp() && <div className='app-start'>
            <h3>App Start Page</h3>
            <div className='flex'>
              <AmbRadio
                label='Dashboard'
                selected={!appStart.path || appStart.path === '/dashboard'}
                onClick={() => {
                  userActions.updateSetting('appStart', { path: '/dashboard' })
                }}
              />
              <AmbRadio
                label='Map'
                selected={appStart.path === '/'}
                onClick={() => {
                  userActions.updateSetting('appStart', { path: '/' })
                }}
              />
            </div>
          </div>}
        </div>
        {adGraph}
        <div className='block'>
          <DeleteAccount />
        </div>
      </>
    )
    if (tab === 'subscriptions') {
      show = user && <ManageSubscriptions user={user} userActions={userActions} />
    } else if (tab === 'keys') {
      show = user && user.info && <ApiKeys />
    } else if (tab === 'units') {
      show = <Settings />
    } else if (tab === 'suggestions') {
      show = (
        <div>
          <div className='block'>
            {isAdmin(user) && <SuggestionDownload socialActions={socialActions} userActions={userActions} />}
            <h3>Suggestion Box</h3>
            <p>Help us grow! Share your thoughts on how we can serve you better.</p>
            <a
              className='btn btn-primary'
              onClick={() => {
                userActions.doModal({
                  type: 'create-post',
                  data: { suggestion: true }
                })
              }}
            >Post A Suggestion</a>
          </div>
          <div className='suggestion-box-posts'>
            <MyTimeline tab='suggestions' />
          </div>
        </div>
      )
    } else if (tab === 'support') {
      show = (
        <div className='block'>
          <h3>Support</h3>
          <p>
            <button className='btn btn-primary' onClick={() => window.mavenoid.push({
              event: 'assistant-mount',
              clientId: 'ma_85hocq4k9t_do936gsi31ak6qpeo3a433sgatofunptdv8s',
              onClose: () => window.mavenoid.push({ event: 'assistant-unmount' })
            })}>Open Support Assistant</button>
          </p>
          <p>At Ambient Weather, we genuinely appreciate our customers and are committed to ensuring you have the best experience with our platform and products. Our US-based customer support team is ready to assist you every step of the way. </p>
          <p>For general questions and troubleshooting, explore our comprehensive <a href='https://ambientweather.com/support' target='_blank' rel='noreferrer'>Help & FAQ section</a>. If you still can't find what you are looking for, <a href='https://ambientweather.com/contact' target='_blank' rel='noreferrer'>click here</a> to complete our support form, and a team member will get back to you as soon as possible. </p>
          <p>
            <Link to='/account/suggestions'>Suggestion Box</Link>
            &nbsp;|&nbsp;
            <a href='https://ambientweather.com/terms' target='_blank' rel='noreferrer'>Terms of Service</a>
          </p>
        </div>
      )
    }
    return (
      <div className='user-account'>
        <header className='main'>
          <h1>
            Account
          </h1>
        </header>
        <div className='tabs'>
          {Object.keys(this.tabs).map(key => <Link key={key} to={`/account/${key}`} className={tab === key ? 'active' : ''}>{this.tabs[key]}</Link>)}
        </div>
        <div className='page-body'>
          {show}
        </div>
      </div>
    )
  }
}
// export default bindUserActions(Account)
export default bindAllActions(withRouter(Account))

function SuggestionDownload ({ socialActions, userActions }) {
  const [loading, setLoading] = useState(false)
  if (loading) {
    return <span className='suggestion-download pull-right'><Loader /></span>
  }
  return (
    <span className='suggestion-download pull-right'>
      <a className='btn btn-default' onClick={() => {
        setLoading(true)
        socialActions.fetchPosts({
          tags: 'suggestion box',
          $limit: 1000,
        })
          .then(async what => {
            let posts = what.data.sort((a, b) => {
              return b.likes || 0 - a.likes || 0
            })
            const  userRes = await userActions.fetchUsers({
              _id: { $in: posts.map(post => post.userId) },
              $limit: 1000
            })
            for(let i = 0; i < posts.length; i++) {
              const p = posts[i]
              if (p.comments && p.comments > 0) {
                posts[i].commentsAll = prop('data', await socialActions.fetchUserActions({
                  type: 'comment',
                  'to._id': p._id,
                  $limit: 1000
                }))
              }
            }
            let csv = 'data:text/csv;charset=utf-8,Suggestion,Date,Likes,Email,Comments...\n'
            posts.forEach(post => {
              csv += `"${post.text.replace(/"/g, '\\"')}${post.image ? `\n${post.image}` : ''}",${new Date(post.createdAt).toISOString()},${post.likes || 0},`
              csv += `${path(['email'], userRes.data.find(user => user._id === post.userId)) || ''}` 
              if (post.commentsAll) {
                post.commentsAll.forEach(comment => {
                  csv += `,"${comment.text.replace(/"/g, '\\"')}"`
                })
              }
              csv += '\n'
            })
            const link = document.createElement('a')
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', 'awn-suggestions.csv')
            document.body.appendChild(link)
            link.click()
            setLoading(false)
          })
        
      }}>Export Suggestions <i className='csv' /></a>
    </span>
  )
}

Account.displayName = 'Account'
