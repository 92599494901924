import React, { Component } from 'react';
import Nav from '../features/user/Nav'
import Modal from 'react-modal'
import ReactNotifications  from 'react-notifications-component'
import { Link } from 'react-router-dom'
import bindUserActions from '../features/user/redux/bindUserActions'
import { reloadApp, pathsChanged, generalModalStyle, isCordova, getUrl, isEmbed, isLoggedIn, isIos, isOauth, showV3, getCurrentBreakpoint, getTheme } from '../common/ambient'
import {
  ExportData, WebcamImage
} from '../features/device/'
import {
  DashboardNav,
  PublicHeader,
  CookiesBanner,
  ErrorBoundary,
  SitewideModals
} from '../components/'
import { 
  MobileNav 
} from '../features/common/'
import PropTypes from 'prop-types';
import { path } from 'ramda'
import classNames from 'classnames'
import { Notifications } from '../features/social'
import { isIosCordovaOrIosBrowser, checkOldLinks, isIosApp, isApp, isAndroidApp, getAppVersion } from '../common/ambient/utils';
import 'animate.css/animate.min.css'
import 'react-notifications-component/dist/theme.css'
require('bootstrap-webpack!./bootstrap.config.js')


class App extends Component {
  static propTypes = {
    children: PropTypes.node,
    // device: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
    // actions: PropTypes.object.isRequired,
    // location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      breakpoint: getCurrentBreakpoint()
    }
    this._resize = ::this._resize
  }
  componentDidMount() {
    window.addEventListener('resize', this._resize, { passive: true })
    checkOldLinks(this.props.location)
    this._resize()
    this.props.actions.fetchUser()

    // IAP setup
    if (isCordova()) {
      if (window.CdvPurchase) {
        const { store, ProductType, Platform } = window.CdvPurchase
        store.register([{
          type: ProductType.PAID_SUBSCIPTION,
          id: 'my_product',
          platform: Platform.TEST,
        }]);
        store.when()
          .approved(transaction => transaction.verify())
          .verified(receipt => receipt.finish())
          .then(() => this.props.actions.refeshUser())

        store.initialize([Platform.TEST]);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._resize)
  }
  componentDidUpdate(prevProps) {
    if (pathsChanged(prevProps, this.props, [['common', 'online']]) && this.props.online) {
      reloadApp()
    }
  }
  _resize() {
    this.setState({
      breakpoint: getCurrentBreakpoint(),
      mobileAddressBarShowing: window.outerHeight - window.innerHeight > 80
    })
  }

  render() {
    const { common, actions, device, user, location } = this.props
    const { sidebar, forecastOpen } = device
    const { open, breakpoint, mobileAddressBarShowing } = this.state
    const d = new Date
    const pageClass = location.pathname.split('/').slice(1, 3).join('-')
    const firstPiece = pageClass.split('-').slice(0, 1)[0] 
    const pageClassPlus = (firstPiece === '' ? 'home' : firstPiece)  + '-page'
    const isHome = pageClassPlus === 'home-page'
    if (!common.online) return <div className='app offline'>
      <p>offline</p>
      <div className='icon' />
    </div>
    const theApp = (
      <div className={classNames(
          'app v4',
          pageClass,
          pageClassPlus,
          path(['modal', 'type'], user),
          'w-' + breakpoint,
          (isLoggedIn(user) && !isOauth(location) ? 'dashboard' : 'loggedout'),
          getTheme(user),
          common.appClass,
          {
            dashboard: /dashboard/.test(pageClass),
            open,
            oauth: isOauth(location),
            sidebar: !!sidebar,
            'forecast-open': forecastOpen,
            'sidebar-fs': sidebar === 'fs',
            'mobile-address': mobileAddressBarShowing,
            embed: isEmbed(location),
            ios: isCordova() && window.device.platform === 'iOS',
            'ios-c-or-b': isIosCordovaOrIosBrowser(),
            cordova: isCordova(),
            'sitewide-modal': !!user.modal,
            v3: showV3(user),
            plus: user.plus,
            'native-app': isApp(),
            'ios-app': isIosApp(),
            'android-app': isAndroidApp()
          }
        )}>
        <ReactNotifications />
        <a
          tabIndex="-1"
          onClick={() => this.setState({ open: !this.state.open })}
          className="close-menu"
        />
        <PublicHeader />
        <div className="mobile-header">
          <a
            tabIndex="-1"
            onClick={() => this.setState({ open: !this.state.open })}
            className="burger"
          />
          <Link className="navbar-brand" to="/dashboard">Home</Link>
        </div>
        <Nav />
        {isLoggedIn(user) && <Notifications />}
        <div className="clearfix loggedout">
          <Link className="loggedout navbar-brand" to={isHome && !isLoggedIn(user) ? '/welcome' : '/dashboard'}>Home</Link>
        </div>
        <DashboardNav
          onChange={() => this.setState({ open: false })}
        />
        <div className="page-container">
          {this.props.children}
        </div>
        <MobileNav />
        <SitewideModals device={device} userActions={actions} user={user} />
        <footer id="footer_main">
          <div className="dashboard">
              © Copyright Ambient, LLC, {d.getFullYear()}. All rights reserved.
            &nbsp;
            &nbsp;
            <span className="br" />
            <a href='https://ambientweather.com/support' rel="noopener" target="_blank">Need Help?</a>
            <span className="sep">|</span>
            Join our 
            &nbsp;
            <a
              href="https://ambientweather.com/faqs/question/view/id/1779/"
              rel="noopener"
              target="_blank"
            >
              Community
            </a>
            {/^account/.test(pageClass) && <span> | v{window._ambientVersion}{isApp() && (' | app: v' + getAppVersion())}</span>}
          </div>
          <div className="powered">
            Powered by <a target="_blank" rel="noopener" href="https://ambientweather.net">Ambient Weather</a>
          </div>
        </footer>
        <CookiesBanner />
      </div>
    )
    return isIosApp() ? <ErrorBoundary location={location} user={user} userActions={actions}>{theApp}</ErrorBoundary> : theApp
  }
}

export default bindUserActions(App)
