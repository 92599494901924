// import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import './DropdownMenu.less';
import classNames from 'classnames';

const getItemKey = (item) => Array.isArray(item) ? item[0] : item;
const getItemLabel = (item) => Array.isArray(item) ? item[1] : item;

const DropdownMenu = ({ items = [], selected, onClick, before, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (item) => {
    onClick(getItemKey(item));
    setIsOpen(false);
  };

  // Find the selected item to get its label
  const selectedItem = items.find(item => getItemKey(item) === selected);
  const selectedLabel = selectedItem ? getItemLabel(selectedItem) : selected;

  return (
    <div className={classNames('common-dropdown-menu', className, { open: isOpen })} ref={dropdownRef}>
      <button
        className="dropdown-menu-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {before && (
          <span className="dropdown-menu-button-before">{before}</span>
        )}
        <span className={classNames('dropdown-menu-button-text', selected)}>
          <i />
          {selectedLabel || 'Select an item'}
        </span>
      </button>
      
      {isOpen && (
        <div className="dropdown-menu-content">
          <ul className="dropdown-menu-list">
            {items
              .filter(item => getItemKey(item) !== selected)
              .map((item, index) => (
                <li
                  key={index}
                  className={classNames('dropdown-menu-list-item', getItemKey(item))}
                  onClick={() => handleSelect(item)}
                >
                  <i/>
                  {getItemLabel(item)}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;

DropdownMenu.propTypes = {};
DropdownMenu.defaultProps = {};
DropdownMenu.displayName = 'DropdownMenu'
