import {
  FETCH_INFO_BEGIN,
  FETCH_INFO_SUCCESS,
  FETCH_INFO_FAILURE,
  FETCH_INFO_DISMISS_ERROR
} from './constants'

export function fetchInfo () {
  return (dispatch) => {
    dispatch({
      type: FETCH_INFO_BEGIN
    })
    return new Promise((resolve, reject) => {
      // uses JSONP so it's annoying
      const jsonpScript = document.createElement('script')
      jsonpScript.setAttribute('src', 'https://geolocation-db.com/jsonp/')
      jsonpScript.id = '__info'
      document.getElementsByTagName('head')[0].appendChild(jsonpScript)
      const cleanup = () => {
        delete window.callback
        const script = document.getElementById('__info')
        if (script) {
          document.getElementsByTagName('head')[0].removeChild(script)
        }
      }
      let sent = false
      const fail = () => {
        cleanup()
        // default to glacier
        dispatch({
          type: FETCH_INFO_SUCCESS,
          data: {
            city: 'Glacier National Park',
            state: 'MT',
            longitude: -114.4062225,
            latitude: 48.6587898
          }
        })
        resolve()
      }
      window.callback = info => {
        sent = true
        if (typeof info !== 'object' || !info.longitude || !info.city || isNaN(info.longitude) || isNaN(info.latitude)) {
          return fail()
        }
        dispatch({
          type: FETCH_INFO_SUCCESS,
          data: info
        })
        cleanup()
        resolve(info)
      }
      setTimeout(() => {
        if (!sent) {
          fail()
        }
      }, 5000)
    })
  }
}

export function dismissFetchInfoError() {
  return {
    type: FETCH_INFO_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_INFO_BEGIN:
      return {
        ...state,
        fetchInfoPending: true,
        fetchInfoError: null,
      };

    case FETCH_INFO_SUCCESS:
      return {
        ...state,
        fetchInfoPending: false,
        fetchInfoError: null,
        ipInfo: action.data
      };

    case FETCH_INFO_FAILURE:
      return {
        ...state,
        fetchInfoPending: false,
        fetchInfoError: action.data.error,
      };

    case FETCH_INFO_DISMISS_ERROR:
      return {
        ...state,
        fetchInfoError: null,
      };

    default:
      return state;
  }
}
