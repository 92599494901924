import React from 'react';
import { path } from 'ramda';
import { deviceIsMine, getDeviceLabel } from '../../common/ambient';
import bindAllActions from '../../common/bindAllActions';
import { Avatar } from '../user';
import FavStar from '../../components/FavStar';
// import PropTypes from 'prop-types';

function DeviceCardHeader ({ children, currentDevice, post, device, user }) {
  if (!currentDevice) return null
  let avatarObj = currentDevice.unitSetting
  if (deviceIsMine(device, currentDevice._id) && path(['info', 'image'], user)) {
    avatarObj = path(['info'], user)
  }
  if (!avatarObj || !avatarObj.image) {
    avatarObj = { image: 'https://ambientweather.net/169ad3add50ee4c257874d239fbdd998.svg' }
  }
  const avatar = (
    <div className='avatar-wrap'>
      <Avatar noDefault u={avatarObj} />
    </div>
  )
  const deviceLabel = getDeviceLabel(currentDevice)
  const deviceLabelPieces = deviceLabel.split(',')
  let displayLocation = deviceLabelPieces.slice(1).join(', ')
  if (post) {
    const remoteLocation = path(['data', 'location'], post)
    if (remoteLocation && !displayLocation) {
      displayLocation = remoteLocation
    }
  }
  return (
    <div className='top device-device-card-header'>
      <div className='meta'>
        {avatar}
        <FavStar currentDevice={currentDevice} />
        <div className='device-name-wrap'>
          {deviceLabelPieces[0] && <span>{deviceLabelPieces[0]}</span>}
          {displayLocation && <b>{displayLocation}</b>}
        </div>
      </div>
      {children}
    </div>
  );
};
export default bindAllActions(DeviceCardHeader)

DeviceCardHeader.propTypes = {};
DeviceCardHeader.defaultProps = {};

DeviceCardHeader.displayName = 'DeviceCardHeader'
