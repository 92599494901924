import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getDeviceSlug, getWebcamArchives } from '../../common/ambient'
import { Link, useLocation } from 'react-router-dom'

export default function WebcamArchive ({ currentDevice }) {
  const [thumbSuff, setThumbSuff] = useState('sunrise')
  const location = useLocation()
  if (!currentDevice) return null
  const videos = getWebcamArchives(currentDevice)
  return (
    <div className='device-webcam-archive'>
      {videos.map(({ fileName, url, date }) => {
        return (
          <div
            className='webcam-thumb'
            key={fileName}
          >
            <Link to={`/dashboard/${getDeviceSlug(currentDevice)}/cam-charts/${date.format('YYYYMMDD')}`}>
              <div
                className='thumb'
                style={{
                  backgroundImage: `url(${url.replace('.mp4', `-${thumbSuff}.jpg`)})`
                }}
              />
            </Link>
            <header>
              <span>
                {date.format('MMM D, YYYY')}
              </span>
              <Link className='play' to={`/dashboard/${getDeviceSlug(currentDevice)}/cam-library/${date.format('YYYYMMDD')}`} >
                play
              </Link>
            </header>
          </div>
        )
      })}
    </div>
  )
};

WebcamArchive.propTypes = {
  currentDevice: PropTypes.object
}
WebcamArchive.defaultProps = {}

WebcamArchive.displayName = 'WebcamArchive'
